//21-septiembre
//--------   Checkout Grid General   -------/
body.resumen-checkout,
body.big-body {
  box-sizing: border-box;
  //All elements box sizing !!Importante
  * {
    box-sizing: border-box;
  }
  #Content {
    min-height: calc(100vh - 164px);
  }

  .content-summary {
    padding-top: 0px;
    padding-left: 12px;
    margin: 0px;
    width: 30%;
  }

  .icon-circle-agencies,
  .icon-chatbox {
    height: 30px;
    width: 30px;
  }

  #container2 div#Content.page-checkout,
  #container2 div#Content.big-content {
    max-width: 1280px;
    padding: 0px 16px;
  }

  #container2 div#Content.page-checkout {
    .content-summary {
      width: 30%;
      margin: 0px;

      #summary {
        width: 100%;
        margin-left: 0px;
        margin-top: 30px;
      }
    }
    #main {
      width: 70% !important;
      margin: 0px !important;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }
}
//--------Ends Checkout Grid General-------/

//--------  Steps  -------/
.resumen-checkout {
  //Box Sizing
  * {
    box-sizing: border-box;
  }

  .row-container-steps {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 20px;
  }
  .container-steps {
    display: flex;
    flex-direction: row;
    align-items: center;
    &:not(:first-child) {
      margin-left: 16px;
    }
    .numbercontainer-steps {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      border-radius: 1991px;
      margin-right: 8px;
    }
    span {
      font-size: 16px;
    }
    .textcontainer-steps {
      hr {
        height: 2px;
        border-width: 0;

        margin: 4px 0px;
      }
    }
  }

  /*.container-steps.active {
    .numbercontainer-steps {
    }
    .textcontainer-steps {
      hr {
      }
    }
  }*/
}
//------Ends Steps-------/

//--------   Resumen Checkout    -------/
.resumen-checkout {
  .checkout-collapse {
    width: 100%;
    margin-bottom: 24px;

    * {
      box-sizing: border-box;
    }

    summary.collapse-header {
      padding-bottom: 12px;
      margin-bottom: 20px;
      width: 100%;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &::-webkit-details-marker {
        display: none;
      }
      &::after {
        content: "\25BC"; /* the new icon */
        margin-right: 5px;
        display: inline-block;
        font-size: 1.2rem;
      }
    }

    details[open] {
      summary.collapse-header {
        &::after {
          content: "\25B2";
        }
      }
    }

    h2 {
      margin: 0px;
    }
  }
  //Checkout Summary
  #summary.checkout-summary {
    box-sizing: border-box;
    background-color: white;
    border-radius: 4px;
    border: 1px solid #ccc;
    width: 100%;
    * {
      box-sizing: border-box;
    }
    .summary-container,
    .summary-addcontainer,
    .payment-info,
    .note {
      padding: 8px 12px;
      margin: 0px;
    }
    .summary-container {
      .greenButton {
        padding: 10px 16px;
        width: 100% !important;
        /* Elevación/6dp */

        box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02),
          0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
      }
    }
    .links {
      text-align: right;
    }
    h3 {
      text-align: left;
      margin-bottom: 6px;
      padding: 0px;
    }
    .item {
      background-color: white;
      border: none;
    }
    .detail-pricing {
      float: none;
      text-align: right;
      padding-bottom: 8px;
      border-bottom: 1px solid gainsboro;
      float: none;
      p {
        font-size: 1.2rem;
        margin: 0px;
        float: none;
        /*span {
        }*/
      }
    }
    .detail-pricing.pricing-info {
      border-bottom: 0px solid white;
    }
    .payment-info,
    .pricing-info {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .itemprice {
        font-size: 0.9em;
        display: flex;
        span {
          width: 100%;
          align-self: flex-end;
          margin-left: 4px;
        }
      }
    }
    .points {
      a,
      h2,
      i {
        font-family: "Poppins", sans-serif;
        color: #ec1280;
        background: none;
      }
      h2 {
        padding: 8px;
        border-bottom: 1px solid gainsboro;
        border-top: 1px solid gainsboro;
      }
    }
    .discount {
      color: #18ac00;
    }
  }
  .row-detail-hotel {
    display: flex;
    gap: 8px;
    align-items: center;
    .logo {
      max-width: 110px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 70px;
    }
    .info-details {
      width: 100%;
      margin: 0px;
    }
    .info-details-hotelName {
      font-size: 1.3em;
    }
  }
  .col-detail-hotel {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .info-details {
      width: 100%;
      ul {
        li {
          &::before {
            content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */

            font-weight: bold; /* If you want it to be bold */
            width: 1em; /* Also needed for space (tweak if needed) */
            margin-right: 5px;
          }
        }
      }
    }
    .collapse-header {
      display: flex;
      flex-direction: row;
      color: #06c;
      gap: 8px;
      &::-webkit-details-marker {
        display: none;
      }
      &::after {
        content: "\25BC";
        font-size: 0.7em;
      }
      &:hover {
        color: #025;
        &::after {
          content: "\25BC";
        }
      }
    }
    .collapse-container[open] {
      .collapse-header {
        &::after {
          content: "\25B2";
        }
      }
    }
    .collapse-detail {
      color: grey;
      margin-bottom: 8px;
      td {
        padding: 1px 8px 1px 0px;
      }
    }
  }

  //general
  #divHotelAdvisory {
    color: unset;
    font-size: unset;
  }

  //Desktop
  div#main,
  #ConTit,
  #mapTabSelector {
    width: 70% !important;
    margin: 0px !important;
    padding: 0 !important;
    float: right;
  }
  #divHotelAdvisory {
    width: unset;
    padding: 0px;
  }

  .hotPagCont,
  .hotPagCont2 {
    padding: 12px;
  }
  .hotPagCont > div {
    margin-bottom: 58px;
  }
  .col-policies,
  .col-policies-not-available {
    width: 100%;
    float: none;
    p {
      padding: 0px 8px;
    }
  }

  .title-policies {
    width: 100%;
    float: none;
    display: block;
  }
  #divComplete,
  #errPag,
  #errPag h1 {
    margin-top: 0px;
  }
}
.divider-pricing {
  border: 0.1px solid gainsboro;
}
//--------Ends Resumen Checkout -------/

//-----        Menu  ------/
.resumen-checkout {
  #splashContent #agencyBar {
    box-shadow: none;
    height: 50px;
    filter: drop-shadow(0px 3px 1px rgba(0, 0, 0, 0.02))
      drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.14))
      drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.12));
  }
  #agencyBar .agencyBar-inner {
    max-width: 1200px;
  }
}
//-----  Ends Menu  ------/

//----------------Collapsables Seccion Agencia-----------//
.resumen-checkout {
  .summary-agency {
    background-color: #eaf5ff;
    padding-left: 12px;
    padding-top: 12px;
    p {
      margin: 0px;
    }
  }
}
//-------------Ends Collapsables Seccion Agencia-------------------//

//----------------------Tours-------------------//
//Main Tour
.big-content {
  .main-tour {
    width: 100%;
    max-width: 100%;
    border: none;
    box-shadow: none;
  }
}

.card {
  border-radius: 4px;
  border: 1px solid #ededed;
  margin: 1rem 0;
  padding: 0px;
}

.card-header {
  background-color: #ededed;
  padding: 0.5rem;
  h4 {
    font-weight: 400;
    color: #333;
  }
}

.card-body {
  padding: 0.5rem;
}

.info-tour-new {
  width: 100%;
  padding: 8px;
}

.tour-heading-price {
  width: 100%;
}

.info-tour-list {
  li {
    margin-bottom: 8px;
  }
}
//----------------------Ends Tours-------------------//

//Commited by Jess Ch mayo 2022

//---    Paquetes    ---//

.inline-b {
  display: inline-block;
}
.vuelo-icon {
  font-size: 1.5em;
  margin-right: 1rem;
}
//Rotacion de elementos
.rot-90 {
  transform: rotate(90deg);
}
.rot-270 {
  transform: rotate(270deg);
}
.flight-date,
.flight-destination {
  margin: 0px 4px;
}
.flight-destination {
  font-weight: bold;
}
.row-destination {
  display: flex;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 4px;
}
.row-detail-airplane {
  display: block;
  width: 100%;
}
.summary-container {
  max-height: unset;
}
.col-detail-flight .info-detail {
  width: 100%;
  margin-top: 12px;
}
.text-center {
  text-align: center !important;
}
.text-right {
  text-align: right !important;
}
.text-left {
  text-align: left !important;
}
.heading-paquete {
  border-bottom: 1px solid gainsboro;
}
.scale,
.resumen-checkout {
  scroll-behavior: smooth;
  scroll-margin-top: 100px;
}
#resFlight {
  background-color: unset;
  border: 0px transparent;
}
h3.resflight {
  font-weight: bold;
  margin: 0px;
}
#main .flight-review {
  //padding: 16px;

  .h-flights {
    background-color: #f5faff;
    border: 1px solid #def;
    padding: 8px 4px;
    min-height: 47px;
    .banNom {
      font-size: 1.2rem;
      display: flex;
      align-items: center;
      border-bottom: 1px solid $gray-100;
      background-color: unset;
      padding-bottom: 12px;
      margin-bottom: 8px;
    }
  }
}
.timeline-flight {
  border-left: 1px dashed rgb(165, 165, 165);
  padding-left: 15px;
  margin-left: 16px;
  margin-bottom: 20px;
  margin-top: 0px;
}
.timeline-flight-item {
  position: relative;
  &::before {
    content: "●";
    color: rgb(165, 165, 165);
    display: block;
    position: absolute;
    top: 50%;

    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: -23px;
    font-size: 26px;
  }
}

//---  Ends Paquetes ---//

//---    Descolapsables    ---//
.resumen-checkout .checkout-collapse .collapse-header {
  border-bottom: 2px solid #c2cdd6;
  &:hover {
    background-color: #eaf5ff;
    transition: background-color 350ms ease-out;
    border-bottom: 2px solid #c2cdd6;
  }
}
.resumen-checkout .checkout-collapse details[open] {
  .collapse-header {
    &:hover {
      background-color: #fff;
      transition: background-color 350ms ease-out;
      border-bottom: 2px solid #c2cdd6;
      transition-delay: 0.8s;
    }
  }
}
//---  Ends Descolapsables ---//

.resumen-checkout #summary.checkout-summary {
  .taxes {
    padding: 0px 12px;
  }
}

.row-container-steps {
  margin-top: 20px;
}

//----------------------Seccion Puntos------------------//
.detail-pricing {
  .value {
    font-weight: bold;
  }
}
.payment-info {
  div {
    width: 100%;
  }
}
.points-available {
  border: 1px solid #db0011 !important;
  padding: 8px;
  margin-bottom: 20px;

  h3 {
    margin: 0px;
  }
}
.AvailableCombinedCashPointsPayment,
.combined-price {
  text-align: right;
}
.itemprice {
  span {
    text-align: right;
  }
}
.note {
  padding: 0px;
}
.ReviewPD {
  background-color: $white;
  border: 1px solid $gray-200;
  padding: 12px;
  margin-bottom: 12px;
}
//----------------------Ends Seccion Puntos ------------------//

//----------------------Responsivo-------------------//
@media (max-width: 992px) {
  body.resumen-checkout,
  body.big-body {
    #Content {
      min-height: calc(100vh - 78px);
      max-width: 1280px;
    }
  }
  //Checkout Grid
  .resumen-checkout #container2 div#Content.page-checkout {
    padding: 16px;
    margin: 0px;

    .content-summary {
      margin: 60px 0px 24px 0px !important;
      padding: 0px;
      width: 100% !important;

      #summary {
        margin: 0px !important;
        padding: 0px;
      }
    }

    #main.sn-review {
      width: 100% !important;
      margin: 0px !important;
    }
  }

  //Steps Responsive
  .resumen-checkout {
    .row-container-steps {
      justify-content: space-around;
      margin-top: 12px;
      margin-bottom: 12px;
    }

    .container-steps {
      .textcontainer-steps {
        display: none;
      }
      .numbercontainer-steps {
        width: 28px;
        height: 28px;
        svg {
          max-width: 16px;
        }
      }
    }
    .container-steps.active {
      .textcontainer-steps {
        display: block;
        span {
          font-size: 12px;
        }
      }
    }
  }

  .points-available {
    margin-left: 0px;
  }
  //Resumen Checkout
  .content-summary {
    margin-bottom: 24px;
  }

  #ReviewPagaDes.ReviewPaDe {
    margin-top: 24px;
  }

  body.resumen-checkout,
  body.big-body {
    #container2 div#Content.page-checkout {
      #main {
        width: 100% !important;
        margin: 0px !important;
        box-shadow: none;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
      }
    }
  }
}

@media (max-width: 768px) {
  .resumen-checkout {
    #summary {
      .itemprice {
        margin-top: 0px;
      }
    }
    .row-detail-hotel {
      flex-direction: column;
    }
    /*.summary-container {
      a {
        display: none;
      }
    }*/
  }
}
