//20 de julio
#agencyContent.agencyContent {
  width: 100%;
  #datTabCon {
    overflow: scroll;
  }
}
/* The Modal (background) */
.modal-backdrop-agents {
  display: none;
  box-sizing: border-box;
  width: 100vw;
  max-width: unset;
  max-height: unset;
  top: 0;
  left: 0;
  margin: 0;
  border-radius: 0;
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4) !important; /* Black w/ opacity */

  opacity: 1 !important;
  * {
    box-sizing: border-box;
  }
  p {
    font-size: 16px;
    line-height: 1.5em;
  }
}

/* Modal Content/Box */
.modal-agents {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  border: 1px solid #888;
  border-radius: 4px;
  overflow: hidden;
  width: 80%; /* Could be more or less, depending on screen size */
  max-width: 600px;
  .modal-content {
    padding: 16px;
  }
  .form {
    margin-top: 12px;
  }
}
.modal-agents,
.agencyContent {
  .row-button {
    padding: 16px 0px;
    justify-content: flex-end;
  }
  .greenButton,
  .greyButton,
  .redButton,
  .botAzu {
    letter-spacing: normal;
    padding: 8px 16px;
    height: unset;
    border: 0px solid white;
    font-size: 14px;
    line-height: 1em;
    min-height: unset;
    margin: 0px;
  }
}
/* Label and Input Styles for Agents Form */
.form-agents {
  gap: 4px !important;
  label {
    margin: none;
  }
  input,
  select {
    padding: 8px;
    width: 100%;
    margin: 0;
    background-color: white;
    border-radius: 4px;
    border: 1px solid #aaa;
    height: 34px;
    &:disabled {
      background-color: #e8e8e8;
    }
    &::placeholder {
      color: #a7a7a7;
    }
    &:invalid {
      border: 1px solid rgb(255, 78, 78);
    }
    &:invalid {
      border: 1px solid rgb(255, 78, 78);
    }
    &:valid {
      border-color: $success-main;
    }
    &:focus {
      border-color: $state-info-text;
    }
  }
  select.area-code,
  input.area-code {
    width: auto;
  }
  small {
    font-size: 0.9em;
  }
  small.message-error {
    color: $error-main;
  }
}

/* The Close Button */
.close,
.close2,
.close3 {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  &:hover,
  &:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
}
.greyButton {
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  background-color: #aaa;
  background-position: 0 -454px;
  background-repeat: repeat-x;
  color: $white !important;
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;
  text-shadow: 0 -1px 0 rgb(119, 119, 119);
  &:hover {
    background-color: rgb(119, 119, 119);
  }
}
.redButton {
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  background-color: $error-main;
  background-position: 0 -454px;
  background-repeat: repeat-x;
  color: #fff !important;
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;
  text-shadow: 0 -1px 0 $error-main;
  &:hover {
    background-color: $error-main;
  }
}
//Table buttons
th.actions {
  min-width: 73px;
}
.table-optionsButton {
  font-size: 18px;
  padding: 0px 6px;
  color: #00aae7;
  &:hover {
    color: #06c;
  }
}
//icons for error success message
.modal-message {
  padding-top: 22px;
  padding-bottom: 22px;
  text-align: center;
  h2 {
    color: $main-text;
  }
}
.success-icon {
  span {
    font-size: 55px;
    color: $success-main;
    line-height: 70px;
  }
}
.error-icon {
  span {
    font-size: 55px;
    color: $error-main;
    line-height: 70px;
  }
}
.warning-icon {
  span {
    font-size: 55px;
    color: $warning;
    line-height: 70px;
  }
}
@media (max-width: 992px) {
  .modal-backdrop-agents {
    top: 34px;
  }
  .agencyContent {
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 106px;
  }
}
