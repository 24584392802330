:root{
    /** Colores Aeromexico vacations *************/
    --color-1: rgb(0, 41, 94);
    --color-2: rgb(250, 0, 115);
    --color-3: rgb(2, 12, 65);
    --color-4: rgb(0, 0, 227);
    /* --color-4: rgb(3, 92, 247); */
    --color-5: rgb(242, 242, 240);
    /** Colores Aeromexico vacations *************/

    /** Estilos botones *************/
    --boton-background: transparent;
    --boton-background-hover: var(--color-2);
    --boton-text-color: var(--color-5);
    --boton-text-color-hover: var(--color-5);
    --boton-border-radius: 4px;
    /** Estilos botones *************/

    /* Este color es el que se aplica a los botones */
    --color-terciario: #e91b2f;
    --texto-botones: white;

    /* Colores para el texto */
    --texto-color-primario: white;
    --texto-color-secundario: #0b2343;
    --font-family: "NeueHaasUnicaPro-Regular", sans-serif;



    /** General ************/
    --text-size-xs: 1.4rem;
    --text-size-sm: 1.6rem;
    --text-size-md: 1.8rem;
    --text-size-lg: 2.2rem;
    --text-size-xl: 2.4rem;
    --text-size-xxl: 3.2rem;
    --text-size-xxxl: 4.2rem;

    --border-radius: 4px;
    --border-size-1: 1px;
    --border-color-1: #C4DCEE;
    --border-color-2: #E6E6E6;

    --base-color-1: white;
    --base-color-2: var(--color-3);
    --base-color-3: #666666;
    --base-color-4: #007CC2;
    --base-color-5: #4C4C4C;
    --base-color-6: #E6F2F9;
    /** General ************/






    /** Resumen de viaje *************/
    --resumen-background-color-1: var(--base-color-6);
    --resumen-background-color-2: white;
    
    
    --resumen-text-color-title: var(--base-color-2);
    --resumen-text-color-1: var(--base-color-2);
    --resumen-text-color-2: var(--base-color-3);
    --resumen-text-color-3: var(--base-color-4);
    --resumen-text-color-4: var(--base-color-5);

    --resumen-border-color-1: var(--border-color-1);
    --resumen-border-color-2: var(--border-color-2);
    --resumen-border-radius: var(--border-radius); /* 4px;*/
    --resumen-border-size-1: var(--border-size-1); /* 1px;*/


    --resumen-boton-background-1: var(--color-2);
    --resumen-boton-background-hover-1: var(--color-2);
    --resumen-boton-text-color-1: var(--color-5);
    --resumen-boton-text-color-hover-1: var(--color-5);
    --resumen-boton-border-color-1: transparent;
    --resumen-boton-border-color-hover-1: transparent;
    --resumen-boton-border-radius-1: var(--border-radius); /* 4px;*/


    --resumen-text-size-xs: var(--text-size-xs); /* 1.4rem;*/
    --resumen-text-size-sm: var(--text-size-sm); /* 1.6rem;*/
    --resumen-text-size-md: var(--text-size-md); /* 1.8rem;*/
    --resumen-text-size-lg: var(--text-size-lg); /* 2.2rem;*/
    --resumen-text-size-xl: var(--text-size-xl); /* 2.4rem;*/
    --resumen-text-size-xxl: var(--text-size-xxl); /* 3.2rem;*/
    --resumen-text-size-xxxl: var(--text-size-xxxl); /* 4.2rem;*/

    /** Resumen de viaje *************/
    --modalDV-background-color-1: var(--base-color-6);

    --modalDV-text-color-title: var(--base-color-2);
    --modalDV-text-color-1: var(--base-color-2);
    --modalDV-text-color-2: var(--base-color-3);
    --modalDV-text-color-3: var(--base-color-4);
    --modalDV-text-color-4: var(--base-color-5);

    --modalDV-border-color-1: var(--border-color-1);
    --modalDV-border-color-2: var(--border-color-2);
    --modalDV-border-radius: var(--border-radius); /* 4px;*/
    --modalDV-border-size-1: var(--border-size-1); /* 1px;*/

    --modalDV-text-size-xs: var(--text-size-xs); /* 1.4rem;*/
    --modalDV-text-size-sm: var(--text-size-sm); /* 1.6rem;*/
    --modalDV-text-size-md: var(--text-size-md); /* 1.8rem;*/
    --modalDV-text-size-lg: var(--text-size-lg); /* 2.2rem;*/
    --modalDV-text-size-xl: var(--text-size-xl); /* 2.4rem;*/
    --modalDV-text-size-xxl: var(--text-size-xxl); /* 3.2rem;*/
    --modalDV-text-size-xxxl: var(--text-size-xxxl); /* 4.2rem;*/
    /** Resumen de viaje *************/
}