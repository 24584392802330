#vuelos-page,
#flights-page,
#results-page,
#resultados-page{
    .modalUS__header--title,
    .modalUS__main--details-toggle-title,
    .modalUS__article--title,
    .modalUS__article--details-title,
    .modalUS__article--container-price-title,
    .modalUS__article--container-price-title-span,
    .modalUS__footer--container-title,
    .modalUS__footer--container-description,
    .modalUS__footer--container-price,
    .modalUS__article--container-price-description,
    .modalUS__skeleton--article-container-price-description,
    .modalUS__skeleton--price-p,
    .modalUS__skeleton--price-h2,
    .modalUS__article--details-description,
    .modalUS__main--details-flight-info-title,
    .modalUS__main--details-flight-info-text{
        margin: 0px;
    }

    .modalUS__main--button{
        padding: 0px;
    }
}