//Commited 18-octubre
//elementos-color
.t-dark {
  color: $main-text;
}
.t-pink {
  color: $primary;
}
.t-clubpoints {
  color: $club-points;
}
.t-secondary {
  color: $purple-500;
}

//elementos flex
.h-stack {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 12px;
}
.v-stack {
  display: flex;
  flex-direction: column;
  width: 100%;
  float: none;
  gap: 12px;
}
.d-none {
  display: none !important;
}
.s-btw {
  justify-content: space-between;
}
.f-end {
  justify-content: flex-end;
}
.ai-center {
  align-items: center;
}
.ai-end {
  align-items: end;
}
//gaps en elementos flex
.gap-xs {
  gap: 4px;
}
.gap-sm {
  gap: 8px;
}
.gap-md {
  gap: 16px;
}
.gap-lg {
  gap: 24px;
}
.gap-xl {
  gap: 32px;
}

//----------------------Responsivo-------------------//
@media (max-width: 992px) {
  .md-v-stack {
    flex-direction: column;
    gap: 8px;
  }

  .md-h-stack {
    flex-direction: row;
    gap: 8px;
  }
}

@media (max-width: 768px) {
  .sm-v-stack {
    flex-direction: column;
    gap: 8px;
  }
  .sm-h-stack {
    flex-direction: row;
    gap: 8px;
  }
}

/*Estilos Checkout fixed*/
.checkoutFormContainer .formField {
  .selectGroup {
    display: flex;
    flex-direction: row;
    gap: 12px;
    &::before {
      content: none;
    }
  }
}
/*ajuste en los logos que ocultan el nombre de la Aerolínea*/
.checkoutSummary img.summaryItem-alogo {
  display: inline-block;
  position: relative;
}
.checkoutFormContainer .formField--cardCCV {
  label {
    white-space: nowrap;
  }
}

// boton de recivir ofertas en el checkout
.checkoutPage .pageContent #CheckReceiveOffers{
  height: fit-content;
}