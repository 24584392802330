.main-tour{
    ul.ActOptions li{
        display: flex;
        flex-wrap: wrap;
        gap: 4px;
        background-color: #f7fbfb;
    
        &:nth-child(2n){
            background-color: transparent;
        }
    }
    
    span.pricenote{
        height: fit-content;
    }

    .tours__min--persons{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2px;
        width: fit-content;
        height: fit-content;
        font-size: 1.2rem;
        line-height: 100%;
        padding: 2px 4px;
        border-radius: 5px;
        font-weight: 600;
    }
    
    .tours__min--persons:hover{
        text-shadow: none;
        cursor: default;
        box-shadow: none;
    }
    .tours__min--persons i{
        font-family: "pth-icon" !important;
        display: inline-block;
        font-size: 15px;
        font-family: icons;
        font-style: normal;
        font-weight: 600;
    }
}