:root {
    --button-color: #fff;
    --button-bg: var(--color-affiliate-1);
    --button-bg-hover: var(--color-affiliate-1);
    --button-bg-disabled: #c1bfc7;
    --button-color-disabled: #f5f5f7;
}

.button__primary,
.button__secondary,
.button__tertiary {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    min-height: 44px;
    min-width: 80px;
    padding: 8px 16px;
    line-height: 100%;
    border: none;
    border-radius: 4px;
    font-size: var(--text-size-sm);
    cursor: pointer;

    &:disabled {
        background-color: var(--button-bg-disabled);
        color: var(--button-color-disabled);
        filter: grayscale(1);

        &:hover {
            color: var(--button-color-disabled);
            background-color: var(--button-bg-disabled);
            cursor: not-allowed;
        }
    }

    &:focus {
        outline: 2px solid #3061f2;
        outline-offset: 2px;
    }
}

.button__primary {
    color: var(--button-color);
    background-color: var(--button-bg);

    &:hover {
        background-color: var(--button-bg-hover);
    }
}

.button__secondary {
    background-color: transparent;
    border: 2px solid var(--button-bg);
    color: var(--button-bg);

    &:hover:not(:disabled) {
        color: var(--button-bg-hover);
        border-color: var(--button-bg-hover);
    }
}

.button__tertiary {
    background: transparent;
    color: var(--button-bg);
    border: none;

    &:hover {
        color: var(--button-bg-hover);
    }

    &:disabled {
        color: var(--button-bg-disabled);
        background-color: transparent;

        &:hover {
            color: var(--button-bg-disabled);
            background-color: transparent;
        }
    }
}

.button__icon {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--button-bg);
    color: #fff;
    padding: 0px;
    min-width: 44px;
    min-height: 44px;
    font-size: 20px;

    &:hover {
        background-color: var(--button-bg-hover);
    }

    &:disabled {
        background-color: #c1bfc7;
        color: #f5f5f7;
    }
}

.button__on--background {
    background-color: #0d0d0d75;
    border: 2px solid #e4e4e6;
    color: #e4e4e6;

    &:hover {
        opacity: 0.9;
    }
}