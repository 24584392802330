//15-noviembre
$icon-font-family: "pth-icon" !default;
$icon-font-path: "fonts" !default;

$icons-account-balance: unquote('"\\61"');
$icons-account-circle: unquote('"\\63"');
$icons-add: unquote('"\\64"');
$icons-add-circle: unquote('"\\65"');
$icons-adults-only: unquote('"\\e901"');
$icons-adults-only-outline: unquote('"\\e900"');
$icons-airline-seat: unquote('"\\66"');
$icons-airplane-up: unquote('"\\e903"');
$icons-airplane-up-outline: unquote('"\\e902"');
$icons-angle-down: unquote('"\\e919"');
$icons-angle-left: unquote('"\\e904"');
$icons-angle-right: unquote('"\\e905"');
$icons-angle-up: unquote('"\\e91b"');
$icons-arrow-back: unquote('"\\67"');
$icons-arrow-downward: unquote('"\\68"');
$icons-arrow-drop-down: unquote('"\\69"');
$icons-arrow-drop-up: unquote('"\\6a"');
$icons-arrow-right: unquote('"\\e906"');
$icons-arrow-up-arrow-down: unquote('"\\e9df"');
$icons-arrow-upward: unquote('"\\6c"');
$icons-attach-money: unquote('"\\6d"');
$icons-b-bradescard: unquote('"\\e907"');
$icons-b-disney: unquote('"\\ea02"');
$icons-b-disney-outline: unquote('"\\e9f5"');
$icons-b-facebook: unquote('"\\e909"');
$icons-b-facebook-square: unquote('"\\e908"');
$icons-b-google-plus: unquote('"\\e90a"');
$icons-b-instagram: unquote('"\\e90b"');
$icons-b-linkedin: unquote('"\\ea03"');
$icons-b-linkedin-square: unquote('"\\ea04"');
$icons-b-pinterest: unquote('"\\e90c"');
$icons-b-pricetravel: unquote('"\\e90d"');
$icons-b-tiktok: unquote('"\\e9f7"');
$icons-b-tiktok-outline: unquote('"\\e9f4"');
$icons-b-travelinn: unquote('"\\e90e"');
$icons-b-tripadvisor: unquote('"\\e9f8"');
$icons-b-twitter: unquote('"\\e910"');
$icons-b-twitter-square: unquote('"\\e90f"');
$icons-b-twitter1: unquote('"\\21"');
$icons-b-whatsapp: unquote('"\\2e"');
$icons-b-xcaret: unquote('"\\e9f9"');
$icons-b-xcaret-color-path1: unquote('"\\e9fa"');
$icons-b-xcaret-color-path2: unquote('"\\e9fb"');
$icons-b-xcaret-color-path3: unquote('"\\e9fc"');
$icons-b-xcaret-color-path4: unquote('"\\e9fd"');
$icons-b-xcaret-color-path5: unquote('"\\e9fe"');
$icons-b-xcaret-color-path6: unquote('"\\e9ff"');
$icons-b-xcaret-color-path7: unquote('"\\ea00"');
$icons-b-xcaret-color-path8: unquote('"\\ea01"');
$icons-b-youtube: unquote('"\\e911"');
$icons-baby: unquote('"\\e913"');
$icons-baby-outline: unquote('"\\e912"');
$icons-balcony: unquote('"\\e916"');
$icons-balcony-2: unquote('"\\e915"');
$icons-balcony-2-outline: unquote('"\\e914"');
$icons-bathtub: unquote('"\\e918"');
$icons-bathtub-outline: unquote('"\\e917"');
$icons-beach: unquote('"\\e91c"');
$icons-beach-club: unquote('"\\e91a"');
$icons-bed: unquote('"\\e91e"');
$icons-bed-outline: unquote('"\\e91d"');
$icons-bed1: unquote('"\\2d"');
$icons-bell: unquote('"\\e920"');
$icons-bell-concierge: unquote('"\\e9ec"');
$icons-bell-concierge-outline: unquote('"\\e9f0"');
$icons-bell-outline: unquote('"\\e91f"');
$icons-book: unquote('"\\e922"');
$icons-book-outline: unquote('"\\e921"');
$icons-broken-image: unquote('"\\6e"');
$icons-bus: unquote('"\\e926"');
$icons-bus-front: unquote('"\\e924"');
$icons-bus-front-outline: unquote('"\\e923"');
$icons-bus-outline: unquote('"\\e925"');
$icons-calendar: unquote('"\\e928"');
$icons-calendar-depart: unquote('"\\70"');
$icons-calendar-outline: unquote('"\\e927"');
$icons-calendar-return: unquote('"\\71"');
$icons-calendar-today: unquote('"\\72"');
$icons-camera: unquote('"\\e92c"');
$icons-camera-cctv: unquote('"\\e92a"');
$icons-camera-cctv-outline: unquote('"\\e929"');
$icons-camera-outline: unquote('"\\e92b"');
$icons-camera-retro: unquote('"\\e9e0"');
$icons-cancel: unquote('"\\73"');
$icons-cancel-circle: unquote('"\\74"');
$icons-car: unquote('"\\e92e"');
$icons-car-outline: unquote('"\\e92d"');
$icons-car-side: unquote('"\\ea08"');
$icons-carry-on-bag: unquote('"\\75"');
$icons-carry-on-bag-off: unquote('"\\41"');
$icons-cash: unquote('"\\e930"');
$icons-cash-outline: unquote('"\\e92f"');
$icons-cash-register: unquote('"\\e9ed"');
$icons-cash-register-outline: unquote('"\\e9f1"');
$icons-check: unquote('"\\e934"');
$icons-check-1: unquote('"\\e931"');
$icons-check-circle: unquote('"\\76"');
$icons-check-mark-circle: unquote('"\\e933"');
$icons-check-mark-circle-outline: unquote('"\\e932"');
$icons-check1: unquote('"\\7a"');
$icons-chevron-down: unquote('"\\e936"');
$icons-chevron-left: unquote('"\\77"');
$icons-chevron-right: unquote('"\\79"');
$icons-chevron-up: unquote('"\\e935"');
$icons-circle-plus: unquote('"\\6b"');
$icons-circle-user: unquote('"\\7b"');
$icons-circuitos: unquote('"\\ea06"');
$icons-circuitos-2: unquote('"\\ea07"');
$icons-city: unquote('"\\e938"');
$icons-city-outline: unquote('"\\e937"');
$icons-clock: unquote('"\\e93a"');
$icons-clock-outline: unquote('"\\e939"');
$icons-clock-rotate-left: unquote('"\\ea09"');
$icons-close: unquote('"\\78"');
$icons-coffee-machine: unquote('"\\e93c"');
$icons-coffee-machine-outline: unquote('"\\e93b"');
$icons-compass: unquote('"\\e93d"');
$icons-couch: unquote('"\\e93f"');
$icons-couch-outline: unquote('"\\e93e"');
$icons-credit-card: unquote('"\\e941"');
$icons-credit-card-outline: unquote('"\\e940"');
$icons-credit-card1: unquote('"\\6f"');
$icons-cruise: unquote('"\\e942"');
$icons-cup: unquote('"\\e944"');
$icons-cup-outline: unquote('"\\e943"');
$icons-departure: unquote('"\\e948"');
$icons-departure-outline: unquote('"\\e947"');
$icons-discount: unquote('"\\e94a"');
$icons-discount-outline: unquote('"\\e949"');
$icons-disney: unquote('"\\e9f6"');
$icons-disney-outline: unquote('"\\ea05"');
$icons-door-hanger: unquote('"\\e94c"');
$icons-door-hanger-outline: unquote('"\\e94b"');
$icons-dropdown: unquote('"\\e94e"');
$icons-dropdown-outline: unquote('"\\e94d"');
$icons-earth-americas-regular: unquote('"\\ea0a"');
$icons-edit: unquote('"\\e9eb"');
$icons-edit-outline: unquote('"\\e9e9"');
$icons-email: unquote('"\\2a"');
$icons-envelope: unquote('"\\e950"');
$icons-envelope-outline: unquote('"\\e94f"');
$icons-error: unquote('"\\42"');
$icons-exclamation-triangle: unquote('"\\e952"');
$icons-exclamation-triangle-outline: unquote('"\\e951"');
$icons-expand-less: unquote('"\\43"');
$icons-expand-more: unquote('"\\44"');
$icons-explore: unquote('"\\45"');
$icons-family: unquote('"\\e954"');
$icons-family-outline: unquote('"\\e953"');
$icons-feedback: unquote('"\\47"');
$icons-file: unquote('"\\48"');
$icons-filter-list: unquote('"\\49"');
$icons-flag-checkered: unquote('"\\e955"');
$icons-flame: unquote('"\\e957"');
$icons-flame-outline: unquote('"\\e956"');
$icons-fligh-takeoff: unquote('"\\4a"');
$icons-flight-land: unquote('"\\4b"');
$icons-gift: unquote('"\\e959"');
$icons-gift-outline: unquote('"\\e958"');
$icons-glass-martini: unquote('"\\e9ef"');
$icons-glass-martini-citrus: unquote('"\\e9ee"');
$icons-glass-martini-citrus-outline: unquote('"\\e9f2"');
$icons-glass-martini-outline: unquote('"\\e9f3"');
$icons-globe-north-america: unquote('"\\e95b"');
$icons-globe-outline-north-america: unquote('"\\e95a"');
$icons-golf: unquote('"\\e95c"');
$icons-grid: unquote('"\\ea14"');
$icons-grid1: unquote('"\\ea0b"');
$icons-guarantee-seal: unquote('"\\e95e"');
$icons-guarantee-seal-outline: unquote('"\\e95d"');
$icons-guests: unquote('"\\4c"');
$icons-hair-dryer: unquote('"\\e960"');
$icons-hair-dryer-outline: unquote('"\\e95f"');
$icons-hamburguer-menu: unquote('"\\e961"');
$icons-happy-face: unquote('"\\e963"');
$icons-happy-face-outline: unquote('"\\e962"');
$icons-hashtag: unquote('"\\e964"');
$icons-heart: unquote('"\\e966"');
$icons-heart-outline: unquote('"\\e965"');
$icons-home: unquote('"\\e968"');
$icons-home-outline: unquote('"\\e967"');
$icons-hotel: unquote('"\\50"');
$icons-id-card: unquote('"\\e96c"');
$icons-id-card-clip: unquote('"\\e96a"');
$icons-id-card-clip-outline: unquote('"\\e969"');
$icons-id-card-outline: unquote('"\\e96b"');
$icons-info: unquote('"\\e96e"');
$icons-info-outline: unquote('"\\e96d"');
$icons-info1: unquote('"\\4d"');
$icons-iron: unquote('"\\e970"');
$icons-iron-1: unquote('"\\e96f"');
$icons-jacuzzi: unquote('"\\e972"');
$icons-jacuzzi-outline: unquote('"\\e971"');
$icons-keyboard-return: unquote('"\\59"');
$icons-keyboard-tab: unquote('"\\33"');
$icons-kids-club: unquote('"\\e973"');
$icons-kids-pool: unquote('"\\e974"');
$icons-kitchen: unquote('"\\e976"');
$icons-kitchen-outline: unquote('"\\e975"');
$icons-label-left: unquote('"\\58"');
$icons-label-right: unquote('"\\57"');
$icons-laptop: unquote('"\\e977"');
$icons-list: unquote('"\\e978"');
$icons-local-dining: unquote('"\\55"');
$icons-location: unquote('"\\e97a"');
$icons-location-outline: unquote('"\\e979"');
$icons-location1: unquote('"\\54"');
$icons-lock: unquote('"\\e97c"');
$icons-lock-outline: unquote('"\\e97b"');
$icons-lock1: unquote('"\\53"');
$icons-magic-magic-wand-streamline: unquote('"\\e97e"');
$icons-magic-magic-wand-streamline-outline: unquote('"\\e97d"');
$icons-mail: unquote('"\\e980"');
$icons-mail-outline: unquote('"\\e97f"');
$icons-map-location: unquote('"\\ea0c"');
$icons-megaphone: unquote('"\\e982"');
$icons-megaphone-outline: unquote('"\\e981"');
$icons-menu: unquote('"\\2b"');
$icons-mobile: unquote('"\\e984"');
$icons-mobile-outline: unquote('"\\e983"');
$icons-money: unquote('"\\e987"');
$icons-money-outline: unquote('"\\e985"');
$icons-money-sign: unquote('"\\e986"');
$icons-monument: unquote('"\\e989"');
$icons-monument-outline: unquote('"\\e988"');
$icons-neutral-face: unquote('"\\e98b"');
$icons-neutral-face-outline: unquote('"\\e98a"');
$icons-next: unquote('"\\e98d"');
$icons-next-outline: unquote('"\\e98c"');
$icons-no-refunds: unquote('"\\e98f"');
$icons-no-refunds-1: unquote('"\\e98e"');
$icons-notifications: unquote('"\\52"');
$icons-opinion-empty: unquote('"\\e990"');
$icons-opinion-half: unquote('"\\e991"');
$icons-opinion-half1: unquote('"\\e992"');
$icons-opinion-half2: unquote('"\\e993"');
$icons-opinion1: unquote('"\\e994"');
$icons-opinion2: unquote('"\\e995"');
$icons-opinion3: unquote('"\\e996"');
$icons-pack: unquote('"\\e998"');
$icons-pack-outline-outline: unquote('"\\e997"');
$icons-parking: unquote('"\\e99a"');
$icons-parking-outline: unquote('"\\e999"');
$icons-pencil: unquote('"\\e99c"');
$icons-pencil-outline: unquote('"\\e99b"');
$icons-people: unquote('"\\e99e"');
$icons-people-outline: unquote('"\\e99d"');
$icons-person: unquote('"\\51"');
$icons-personal-bag: unquote('"\\4f"');
$icons-pet: unquote('"\\e9a0"');
$icons-pet-outline: unquote('"\\e99f"');
$icons-phone: unquote('"\\e9a4"');
$icons-phone-1: unquote('"\\e9a1"');
$icons-phone-outline: unquote('"\\e9a3"');
$icons-phone-outline-1: unquote('"\\e9a2"');
$icons-phone1: unquote('"\\4e"');
$icons-photo-camera: unquote('"\\30"');
$icons-picture: unquote('"\\e9a6"');
$icons-picture-outline: unquote('"\\e9a5"');
$icons-piggy-bank: unquote('"\\e9a8"');
$icons-piggy-bank-outline: unquote('"\\e9a7"');
$icons-place: unquote('"\\31"');
$icons-plane: unquote('"\\e9b2"');
$icons-plane-airport: unquote('"\\e9aa"');
$icons-plane-airport-outline: unquote('"\\e9a9"');
$icons-plane-arrival: unquote('"\\e9ac"');
$icons-plane-arrival-outline: unquote('"\\e9ab"');
$icons-plane-departure: unquote('"\\e9ae"');
$icons-plane-departure-outline: unquote('"\\e9ad"');
$icons-plane-engine: unquote('"\\e9b0"');
$icons-plane-engine-outline: unquote('"\\e9af"');
$icons-plane-outline: unquote('"\\e9b1"');
$icons-plate-utensils: unquote('"\\e945"');
$icons-play-circle: unquote('"\\32"');
$icons-pool: unquote('"\\ea0d"');
$icons-present-gift: unquote('"\\e9b4"');
$icons-present-gift-outline: unquote('"\\e9b3"');
$icons-print: unquote('"\\e9b6"');
$icons-print-outline: unquote('"\\e9b5"');
$icons-receipt: unquote('"\\e9b8"');
$icons-receipt-outline: unquote('"\\e9b7"');
$icons-refresh: unquote('"\\e9ba"');
$icons-refresh-outline: unquote('"\\e9b9"');
$icons-remove: unquote('"\\e9bb"');
$icons-remove-circle: unquote('"\\35"');
$icons-remove1: unquote('"\\34"');
$icons-romance: unquote('"\\e9bf"');
$icons-romance-outline: unquote('"\\e9be"');
$icons-sad-face: unquote('"\\e9c1"');
$icons-sad-face-outline: unquote('"\\e9c0"');
$icons-save: unquote('"\\e9c5"');
$icons-save-box: unquote('"\\e9c3"');
$icons-save-box-outline: unquote('"\\e9c2"');
$icons-save-outline: unquote('"\\e9c4"');
$icons-save1: unquote('"\\2c"');
$icons-search: unquote('"\\e9c7"');
$icons-search-outline: unquote('"\\e9c6"');
$icons-search1: unquote('"\\36"');
$icons-share-nodes: unquote('"\\ea0e"');
$icons-shield: unquote('"\\e9c8"');
$icons-shield-halved: unquote('"\\ea0f"');
$icons-ship: unquote('"\\ea10"');
$icons-snow: unquote('"\\e9c9"');
$icons-spa: unquote('"\\ea15"');
$icons-spa-outline: unquote('"\\ea16"');
$icons-star: unquote('"\\e9cb"');
$icons-star-half: unquote('"\\e9bd"');
$icons-star-outline: unquote('"\\e9ca"');
$icons-store: unquote('"\\e9cd"');
$icons-store-outline: unquote('"\\e9cc"');
$icons-subdirectory-arrow-right: unquote('"\\38"');
$icons-suitcase: unquote('"\\e9cf"');
$icons-suitcase-medical: unquote('"\\ea11"');
$icons-suitcase-outline: unquote('"\\e9ce"');
$icons-suitcase-rolling: unquote('"\\7c"');
$icons-supersaver: unquote('"\\e9d1"');
$icons-supersaver-outline: unquote('"\\e9d0"');
$icons-swap-vert: unquote('"\\39"');
$icons-swim: unquote('"\\e9d2"');
$icons-talk: unquote('"\\e9d4"');
$icons-talk-outline: unquote('"\\e9d3"');
$icons-ticket: unquote('"\\e9d6"');
$icons-ticket-outline: unquote('"\\e9d5"');
$icons-trash: unquote('"\\e9ea"');
$icons-trash-outline: unquote('"\\e9e8"');
$icons-trophy: unquote('"\\e9d8"');
$icons-trophy-outline: unquote('"\\e9d7"');
$icons-umbrella-beach: unquote('"\\ea12"');
$icons-user: unquote('"\\e9dc"');
$icons-user-group: unquote('"\\e9da"');
$icons-user-group-outline: unquote('"\\e9d9"');
$icons-user-outline: unquote('"\\e9db"');
$icons-utensils: unquote('"\\e946"');
$icons-utensils-slash: unquote('"\\e9bc"');
$icons-van-shuttle: unquote('"\\ea13"');
$icons-video-sq: unquote('"\\e9de"');
$icons-video-sq-outline: unquote('"\\e9dd"');
$icons-visibility: unquote('"\\22"');
$icons-visibility-off: unquote('"\\23"');
$icons-wallet: unquote('"\\e9e2"');
$icons-wallet-outline: unquote('"\\e9e1"');
$icons-warning: unquote('"\\24"');
$icons-water-slide: unquote('"\\e9e3"');
$icons-wheelchair: unquote('"\\e9e4"');
$icons-wheeled-luggage: unquote('"\\28"');
$icons-wheeled-luggage-add: unquote('"\\27"');
$icons-wheeled-luggage-off: unquote('"\\26"');
$icons-wifi: unquote('"\\e9e5"');
$icons-work: unquote('"\\25"');
$icons-world: unquote('"\\62"');
$icons-zoom-in: unquote('"\\e9e7"');
$icons-zoom-in-outline: unquote('"\\e9e6"');
