// Estilos para la vista de /cuenta/restablecer
#restablecer-page #container2 #Content,
#cruiseContent {
  padding: 20px 16px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#restablecer-page #login {
  width: 100%;
  display: flex;
  padding: 15px 0 0;
  margin: 2em auto;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 480px;
}
#restablecer-page #login h1 {
  font-size: 2.8rem;
}
#restablecer-page #login .formbox {
  width: 100%;
  max-width: 480px;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 15px 20px;
  margin: 1em auto;
  border-radius: 4px;
  box-shadow: 0px 3px 1px rgba(0, 0, 0, 0.02), 0px 2px 2px rgba(0, 0, 0, 0.14),
    0px 1px 5px rgba(0, 0, 0, 0.12);
  border: 1px solid #cde;
  background: #f9fafc;
  box-sizing: border-box;
}

#restablecer-page #login .formbox .formRow {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#restablecer-page #login .formbox .formRow label {
  margin-bottom: 10px;
}
#restablecer-page #login .formbox .formRow input {
  width: 100% !important;
  max-width: 100% !important;
  background: #ffffff;
  border: 1px solid #ccc;
  display: block;
  margin: 0;
  padding: 12px;
  border-radius: 5px;
  box-sizing: border-box;
}
#restablecer-page #login .formbox .formSubmit .signin {
  padding: 8px 20px;
  font-size: 14px;
  text-decoration: none;
  text-shadow: none;
  border-radius: 4px;
}
