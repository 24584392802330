.modalUS__main--details{
    display: none;

    @media (min-width: 800px) and (min-height: 690px){
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        width: 100%;
        height: auto;
    }
}

.modalUS__main--details-toggle{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    width: auto;
    margin: 0px 24px 0px 0px;
    cursor: pointer;

    @media (min-width: 800px) and (min-height: 720px){
        margin: 8px 24px 0px 0px;
    }
    @media (min-width: 800px) and (min-height: 740px){
        margin: 16px 24px 0px 0px;
    }
}

.modalUS__main--details-toggle-title{
    font-size: 1.6rem;
    font-family: var(--font-family);
    color: var(--upsell-text-color-2);
}
.modalUS__main--details-toggle-icon{
    margin-top: 4px;
    transition: transform 0.2s ease;
    
    path{
        fill: var(--upsell-text-color-2);
    }
}

.modalUS__main--details-toggle-icon-open{
    transform: rotate(180deg);
    margin-top: 3px;
}

.modalUS__main--details-flight-info-collapsed{
    overflow: hidden;
    height: 0px !important;
    padding: 0px 28px !important;
}

.modalUS__main--details-flight-info{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 48px;
    width: 100%;
    height: auto;
    background-color: var(--upsell-background-color-2);
    padding: 2px 28px;
    transition: alL 0.2s linear;


    @media (min-width: 800px) and (min-height: 710px){
        padding: 6px 28px;
    }

    @media (min-width: 800px) and (min-height: 730px){
        padding: 12px 28px;
    }
}

.modalUS__main--details-flight-info-container{
    display: flex;
    gap: 8px;
}

.modalUS__main--details-flight-info-picture,
.modalUS__main--details-flight-info-picture-img{
    width: 24px;
    height: 27px;
}

.modalUS__main--details-flight-info-title {
    font-size: 1.6rem;
    font-family: var(--font-family);
    color: var(--upsell-text-color-titulo-3);
}
.modalUS__main--details-flight-info-text {
    font-size: 1.2rem;
    font-family: var(--font-family);
    color: var(--upsell-text-color-titulo-3);
}
.modalUS__main--details-flight-info-route {
    font-weight: bold;
    font-size: 1.3rem;
}