$basica: #493D3A;
$clasica: #017197;
$clasica-flex: #017197;
$am-plus: #035CF7;
$am-premier: #5215FC;


@font-face {
    font-family: icons;
    font-style: normal;
    font-weight: 400;
    src: url(https://b2b-b2b2c.s3.amazonaws.com/es-mx/fonts/iconosamv.woff) format("truetype")
}



.modalUS__article--airline-am{
    .modalUS__article--icon{
        position: relative;
        display: grid; 
        place-items: center;   
        width: 30px;
        height: 30px;
    }

    .modalUS__article--icon::before,
    .modalUS__article--li-icon::before{
        display: inline-block;
        font-family: icons;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 1;
    }
    
    .modalUS__article--li-icon::before{
        font-size: 25px;
    }
}


// Tarifa Básica
.modalUS__article--am-basica .modalUS__article--icon::before{
    content: "\EA76";
}
.modalUS__article--am-basica .modalUS__article--icon::before,
.modalUS__article--am-basica .modalUS__article--li-icon::before,
.modalUS__article--am-basica-flex .modalUS__article--icon::before{
    color: $basica;
}
// Tarifa Básica



// Tarifas Clásicas
.modalUS__article--am-clasica .modalUS__article--icon::before,
.modalUS__article--am-clasica-flex .modalUS__article--icon::before{
    content: "\EA78";
}
.modalUS__article--am-clasica .modalUS__article--icon::before,
.modalUS__article--am-clasica-flex .modalUS__article--icon::before,
.modalUS__article--am-clasica .modalUS__article--li-icon::before,
.modalUS__article--am-clasica-flex .modalUS__article--li-icon::before{
    color: $clasica;
}
// Tarifas Clásicas


// Tarifas AM
.modalUS__article--am-am-plus .modalUS__article--icon::before,
.modalUS__article--am-am-plus-flex .modalUS__article--icon::before{
    content: "\EA75";
}
.modalUS__article--am-am-plus-flex .modalUS__article--icon::before,
.modalUS__article--am-am-plus .modalUS__article--icon::before,
.modalUS__article--am-am-plus-flex .modalUS__article--li-icon::before,
.modalUS__article--am-am-plus .modalUS__article--li-icon::before{
    color: $am-plus;
}
// Tarifas AM


// Tarifas Premier
.modalUS__article--am-premier .modalUS__article--icon::before,
.modalUS__article--am-premier-flex .modalUS__article--icon::before,

.modalUS__article--am-premier-one .modalUS__article--icon::before,
.modalUS__article--am-premier-one-flex .modalUS__article--icon::before,

.modalUS__article--am-clase-premier-light .modalUS__article--icon::before,
.modalUS__article--am-clase-premier-light-flex .modalUS__article--icon::before{
    content: "\EA7B";
}

.modalUS__article--am-premier .modalUS__article--icon::before,
.modalUS__article--am-premier .modalUS__article--li-icon::before,

.modalUS__article--am-premier-flex .modalUS__article--icon::before,
.modalUS__article--am-premier-flex .modalUS__article--li-icon::before,

.modalUS__article--am-premier-one .modalUS__article--icon::before,
.modalUS__article--am-premier-one .modalUS__article--li-icon::before,

.modalUS__article--am-premier-one-flex .modalUS__article--icon::before,
.modalUS__article--am-premier-one-flex .modalUS__article--li-icon::before,

.modalUS__article--am-clase-premier-light .modalUS__article--icon::before,
.modalUS__article--am-clase-premier-light .modalUS__article--li-icon::before,

.modalUS__article--am-clase-premier-light-flex .modalUS__article--icon::before,
.modalUS__article--am-clase-premier-light-flex .modalUS__article--li-icon::before{
    color: $am-premier;
}
// Tarifas Premier



// Equpaje documentado
.modalUS__article--li-icon-equipaje-documentado::before{
    content: "\EB4F";
    padding: 5px 0px 0px 0px;
}
// Equpaje documentado

// Equipaje de mano
.modalUS__article--li-icon-maleta-de-mano::before{
    content: "\EB3D";
    padding: 3px 0px 0px 0px;
}
// Equipaje de mano

//Reembolsable
.modalUS__article--li-icon-reembolsable::before{
    content: "\EB1A";    
    font-size: 18px;
    padding: 7px 3px 0px 4px;
}
//Rembolsable

// Cambios
.modalUS__article--li-icon-cambios::before{
    // content: "\EA2D";
    content: "\EA24";
    font-size: 18px;
    padding: 7px 4px 0px 4px;
} 
// Cambios

// Asiento
.modalUS__article--li-icon-asiento::before{
    content: "\EA77";
    font-size: 18px;
    padding: 7px 7px 0px 4px;
}
// Asiento

//wifi  
.modalUS__article--li-icon-wifi::before{
    content: "\EA1C";
    font-size: 12px;
    padding: 7px 5px 0px 4px;
}
//wifi


.modalUS__article{
    &.modalUS__article--am-basica{
        border-color: $basica;
    
        & .modalUS__article--title{
            color: $basica;
        }
    }
    &.modalUS__article--am-clasica,
    &.modalUS__article--am-clasica-flex{
        border-color: $clasica;
    
        & .modalUS__article--title{
            color: $clasica;
        }
    }
    &.modalUS__article--am-am-plus,
    &.modalUS__article--am-am-plus-flex{
        border-color: $am-plus;
    
        & .modalUS__article--title{
            color: $am-plus;
        }
    }
    &.modalUS__article--am-premier,
    &.modalUS__article--am-premier-flex,
    &.modalUS__article--am-premier-one,
    &.modalUS__article--am-premier-one-flex,
    &.modalUS__article--am-clase-premier-light,
    &.modalUS__article--am-clase-premier-light-flex{
        border-color: $am-premier;
    
        & .modalUS__article--title{
            color: $am-premier;
        }
    }


    &.modalUS__article--selected{
        &.modalUS__article--am-basica{
            .modalUS__article--container-price{
                background-color: $basica;
            }
    
            & .modalUS__article--checkbox-custom{
                background-color: $basica;
        
                &::after{
                    background-color: $basica;
                }
            }
        }
    
        &.modalUS__article--am-clasica,
        &.modalUS__article--am-clasica-flex{
            .modalUS__article--container-price{
                background-color: $clasica;
            }
    
            & .modalUS__article--checkbox-custom{
                background-color: $clasica;
        
                &::after{
                    background-color: $clasica;
                }
            }
        }
    
        &.modalUS__article--am-am-plus,
        &.modalUS__article--am-am-plus-flex{
            .modalUS__article--container-price{
                background-color: $am-plus;
            }
    
            & .modalUS__article--checkbox-custom{
                background-color: $am-plus;
        
                &::after{
                    background-color: $am-plus;
                }
            }
        }
    
        &.modalUS__article--am-premier,
        &.modalUS__article--am-premier-flex,
        &.modalUS__article--am-premier-one,
        &.modalUS__article--am-premier-one-flex,
        &.modalUS__article--am-clase-premier-light,
        &.modalUS__article--am-clase-premier-light-flex{
            .modalUS__article--container-price{
                background-color: $am-premier;
            }
    
            & .modalUS__article--checkbox-custom{
                background-color: $am-premier;
        
                &::after{
                    background-color: $am-premier;
                }
            }
        }
    }
    // Card seleccionada
    
}




.modalUS__article--am-clasica .modalUS__article--section-price{
    background-color: #FBFCFD;
    border-top: 1px solid rgba(1, 113, 151, 0.2);
}
.modalUS__article--am-clasica-flex .modalUS__article--section-price{
    background-color: #F0F5F8;
    border-top: 1px solid rgba(1, 113, 151, 0.2);

    & .modalUS__article--container-price-title-span{
        color: $clasica;
    }
}
.modalUS__article--am-am-plus .modalUS__article--section-price{
    background-color: #FBFCFF;
    border-top: 1px solid rgba(3, 92, 247, 0.2);
}
.modalUS__article--am-am-plus-flex .modalUS__article--section-price{
    background-color: #EFF3FD;
    border-top: 1px solid rgba(3, 92, 247, 0.2);

    & .modalUS__article--container-price-title-span{
        color: $am-plus;
    }
}
.modalUS__article--am-premier .modalUS__article--section-price,
.modalUS__article--am-clase-premier-light .modalUS__article--section-price,
.modalUS__article--am-premier-one .modalUS__article--section-price{
    background-color: #FCFBFF;
    border-top: 1px solid rgba(82, 21, 252, 0.2);
}
.modalUS__article--am-premier-flex .modalUS__article--section-price,
.modalUS__article--am-clase-premier-light-flex .modalUS__article--section-price,
.modalUS__article--am-premier-one-flex .modalUS__article--section-price{
    background-color: #F2F0FE;
    border-top: 1px solid rgba(82, 21, 252, 0.2);

    & .modalUS__article--container-price-title-span{
        color: $am-premier;
    }
}