@import "../main/styles";
@import "../variables/varColores-General";

.id-container {
    max-width: 380px;
    margin: 3rem auto 1rem auto;
}

.section-check {
    border: 1px #ddd solid;
    border-radius: 4px;
    padding: 1rem;
    max-width: 380px;
    margin: 0 auto;
    margin-top: 5%;
}

label {
    display: inline-block;
}

.form-code, .form-check {
    margin: 1rem 0;
}

.ckeck-button {
    margin: 1.6rem 0;
}

.form-label {
    margin-bottom: .5rem;
}

.form-control {
    display: block;
    width: 100%;
    padding: 1rem 0;
    font-size: 1.5rem;
    font-weight: 400;
    border-radius: 4px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
}

.btn-ckeck-code {
    width: 100%;
    padding: 1rem;
    text-align: center !important;
    background: $brand-primary;
    border: 1px solid $brand-primary;
    text-align: center !important;
    color: #fff;
}

.section-links {
    text-align: center;
}

.msj-error {
    color: brown;
}

.msj-sent {
    color:darkgreen;
}

@media (max-width: 600px) {
    .section-check {
        max-width: 100%;
    }
}