//21-septiembre
body.big-body#chkOut {
  height: auto;
  background: $white;

  #Content {
    min-height: 100vh;
    width: 100% !important;
    border: 0px solid $white;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  #footer {
    width: 100% !important;
    margin: 0px;
  }

  .font-icons {
    font-family: $icon-font-family !important;
  }

  .container-checkout #Content {
    padding: 0px;
    margin-top: 50px;
    height: 100%;
    min-height: calc(100vh - 110px);
  }
  .po-header {
    .title {
      margin: 0px 0px 12px 0px !important;
      color: $black;
    }
  }
}

.checkout {
  width: 100%;
  display: grid;
  grid-template-columns: 8fr 4fr;
  grid-gap: 32px;
  padding: 16px;
}
.payment-options {
  display: grid;
  grid-gap: 12px;
  padding-top: 16px;
}
.po-container {
  cursor: pointer;
  background: $white;
  border: 1px solid $gray-200;
  border-radius: 4px;
  &:hover {
    /* Primary/Main */
    border: 2px solid #ea0074;
    /* Elevación/4dp */

    box-shadow: $elevacion-4dp;

    transition: box-shadow 200ms ease-out;
  }
  .title {
    .font-icons {
      color: $primary !important;
    }
  }
}

.po-container.active {
  cursor: pointer;
  /* Primary/Main */
  border: 2px solid $primary;
  /* Elevación/4dp */

  box-shadow: $elevacion-4dp;
  .po-content {
    display: block;
  }
  .title {
    color: $primary !important;
  }
}
.po-header {
  padding: 16px;

  .title {
    display: flex;
    align-items: center;
  }
  .subtitle {
    margin-bottom: 0px;
  }
  span.font-icons {
    font-size: 1.6em;
    margin-right: 8px;
  }
}
.po-content {
  padding: 0px 16px 16px 16px;
  display: none;
}

.payment-options.disabled {
  .po-container,
  .po-container:hover {
    background: $gray-10;
    border: 1px solid $gray-200;
    box-shadow: none;
    cursor: not-allowed;
    color: $gray-400;
    .t-pink {
      color: $gray-500 !important;
    }
    .title {
      .font-icons {
        color: $gray-400 !important;
      }
    }
  }
}

body.big-body#chkOut {
  .alert-container {
    margin-top: 36px;
    margin-bottom: 24px;
  }
  .warning-container {
    padding: 10px 22px;
    background: $yellow-10;
    font-size: 0.9rem;
    border: 1px solid $yellow-500;
    border-radius: $radius-sm;
    position: relative;

    overflow: hidden;
    &::before {
      position: absolute;
      content: "";
      display: block;
      background-color: $yellow-500;
      width: 8px;
      top: 0px;
      bottom: 0px;
      left: 0px;
    }
    .header {
      h3.title {
        display: flex;
        align-items: center;
        margin: 0px !important;
        color: $yellow-900;
        .font-icons {
          color: $warning !important;
          font-size: 1.6em;
          margin-right: 8px;
        }
      }
    }
    .subtitle {
      margin-bottom: 0px;
    }
  }
  .warning-container.danger {
    background: $red-10;
    border: 1px solid $red-500;
    border-radius: $radius-sm;
    box-shadow: $elevacion-4dp;
    &::before {
      background-color: $red-500;
    }
    .header {
      h3.title {
        color: $red-900;
        .font-icons {
          color: $red !important;
        }
      }
    }
  }
}
// Resumen Checkout
.resumen {
  margin-top: 10px;
  font-size: 12px;
  ul,
  a {
    font-size: 1em;
  }
  span.font-icons {
    color: $gray-300;
    font-size: 1.2em;
    margin-right: 5px;
  }
  h2 {
    font-size: 1.2em;
  }
  h5 {
    font-size: 1em;
    color: $gray-400;
    margin-bottom: 2px;
    font-weight: 300;
  }
  .resumen-header {
    margin-bottom: 12px;
    padding: 0px 8px;
  }
  .mobile-content {
    display: none;
  }
  .pasajero-info {
    margin-bottom: 6px;
    padding-bottom: 6px;
    border-bottom: 1px dashed $gray-200;
  }
  .resumen-info {
    padding: 0px 8px;
  }
  .servicios-info,
  .hotel-info {
    margin-bottom: 6px;
    padding-bottom: 6px;
    border-bottom: 1px solid $gray-200;
    .info-details {
      ul,
      ul li {
        margin: 0px;
      }
    }
  }
  .flight-info {
    margin-bottom: 6px;
    padding-bottom: 6px;
    border-bottom: 1px solid $gray-200;
    .info-flight {
      margin-bottom: 4px;
    }
    .row-destination {
      margin: 0px;
    }
    .vuelo-icon {
      font-size: 1.2em;
      color: $gray-300;
    }
  }
  .paquete-pricing {
    margin: 4px 0px 8px;
    font-weight: 500;
  }
  .addon {
    margin-bottom: 6px;

    .addon-pricing {
      gap: 0px;
      &:last-child {
        border-bottom: 1px solid $gray-200;
        padding-bottom: 6px;
      }
      .pricing {
        min-width: 98px;
        text-align: right;
      }
    }
  }
  .summary-container {
    margin-top: 12px;
    margin-bottom: 24px;
    .greenButton {
      width: 100%;
      box-shadow: $elevacion-4dp;
      padding: 10px 16px;
    }
  }
}

.resumen.paquete {
  .pasajero-info,
  .hotel-info,
  .flight-info {
    border-style: dashed;
  }
}

.resumen-pricing {
  padding: 0px 8px;
  margin-bottom: 16px;
  .subtotal {
    margin-bottom: 4px;
    color: $gray-500;
    .pricing {
      min-width: 98px;
      text-align: right;
    }
  }
  .descuento {
    .pricing {
      min-width: 98px;
      text-align: right;
    }
  }
  // .c-points {
  //   font-family: $font-family-headers;
  // }
  .total {
    margin-top: 4px;
    margin-bottom: 4px;
    font-size: 12px;
    font-weight: 500;
  }
  .tax-message {
    text-align: center;
  }
  .club-points {
    margin: 8px 0px;
    text-align: center;
  }
}
.resumen-Cpoints {
  border: 1px solid $gray-200;
  border-radius: 4px;
  overflow: hidden;
  height: 100%;
  .header {
    gap: 0px;
    padding: 6px 8px;
    border-bottom: 1px solid $gray-200;
  }
  .content {
    gap: 0px;
    padding: 4px 8px;
    .Cpoints-input {
      gap: 0px;
      position: relative;

      &::before {
        display: block;
        position: absolute;
        top: 8px;
        left: 6px;
        content: $icons-star;
        z-index: 2;
        font-family: $icon-font-family;
        font-size: 18px;
        color: $primary;
      }
      .input {
        padding: 4px 0px 4px 32px;
        border: 1px solid $gray-200;
        border-radius: 4px 0px 0px 4px;
        /* Chrome, Safari, Edge, Opera */
        -webkit-appearance: none;
        /* Firefox */
        -moz-appearance: textfield;
        width: 100%;
      }
      .button {
        background-color: $club-points;
        color: $white;
        border: 0px solid $white;
        border-radius: 0px 4px 4px 0px;
        padding: 4px 8px;
      }
      .button.greyButton {
        background-color: $gray-300;
        color: $white;
        border: 0px solid $white;
        border-radius: 4px;
        margin-left: 8px;
        font-weight: 400;
      }
    }
  }
  .content {
    display: none;
  }
}
.resumen-Cpoints.active {
  .header {
    span {
      rotate: 180deg;
    }
  }
  .content {
    display: block;
  }
}

//Responsive
@media (max-width: 992px) {
  .checkout {
    grid-template-columns: 7fr 5fr;
  }
  .ageLog div#container2 {
    margin-top: 90px;
  }
}
@media (max-width: 768px) {
  body.big-body {
    height: auto;

    #splashContent {
      padding: 0px;
      div#agencyBar {
        position: fixed;
      }
    }
    .container-checkout #Content {
      margin-top: 90px;
      min-height: calc(100vh - 150px);
    }

    .container-checkout .checkout {
      display: grid;
      grid-template-columns: 1fr;
    }
  }
  .payment {
    grid-row-start: 2;
  }
  .resumen {
    .mobile-content {
      display: flex;
      padding: 8px;
    }
    .resumen-info {
      .hotel-info,
      .flight-info,
      .addon,
      .servicios-info {
        display: none;
      }
    }

    .summary-container {
      background-color: $white;
      position: fixed;
      bottom: 0px;
      left: 0px;
      margin: 0px;
      padding: 8px 24px 32px 24px;
      width: 100%;
      box-shadow: $elevacion-6dp;
      z-index: 3;
    }
  }
  .resumen.active {
    .mobile-content {
      span {
        rotate: 180deg;
      }
    }
    .hotel-info,
    .flight-info,
    .addon,
    .servicios-info {
      display: block;
    }
  }
}

input:disabled.greenButton {
  background: $gray-100;
  border-color: $gray-300;
  color: $gray-500;
  text-shadow: none;
}
