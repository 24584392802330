@media (max-width: 415px) {
  .table-price td.tot {
    width: auto;
    max-width: 280px !important;
    white-space: normal !important;
    overflow: visible !important;
  }
}
.collapsed .collapsedLabel {
  display: inline-block;
  width: 100px !important;
}
