.modalGC__section{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 100dvh;
    background-color: rgba(0, 0, 0, .4);
    z-index: 100000;
    top: 0;
    left: 0;
    
    
    @media (min-width: 600px){
        align-items: center;
    }
}

.modalGC__section--entry-animation{
    // animation: popUpDV 0.3s ease;

    animation: fadeInDV 0.3s ease;
    animation-fill-mode: forwards;

    // @media (min-width: 768px){
    //     animation: fadeInDV 0.3s ease;
    //     animation-fill-mode: forwards;
    // }
}

.modalGC__section--exit-animation{
    // animation: popDownDV 0.3s ease;

    animation: fadeOutDV 0.3s ease;
    animation-fill-mode: forwards;

    // @media (min-width: 768px){
    //     animation: fadeOutDV 0.2s ease;
    //     animation-fill-mode: forwards;
    // }
}

.modalGC__section--container{
    width: 97%;
    height: auto;
    background-color: white;
    border-radius: var(--modalDV-border-radius);

    @media (min-width: 768px){
        max-width: 600px;
    }
}

.modalGC__header{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 12px 16px;
    border-bottom: 1px solid var(--modalDV-border-color-2);
    @media (min-width: 768px){
        padding: 20px 24px;
        max-height: 600px;
    }
}
.modalGC__header--container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.modalGC__header--container-title{
    font-size: var(--modalDV-text-size-lg);
    color: var(--modalDV-text-color-1);
}
.modalGC__header--container-close{
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.modalGC__header--family-text{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    font-size: var(--modalDV-text-size-sm);
    color: var(--modalDV-text-color-1);    
}

.modalGC__main{
    padding: 12px 16px;

    @media (min-width: 665px){
        padding: 20px 24px;
    }
}

.modalGC__main--section{
    width: auto;
}

.modalGC__section--container-alert{
    // border-left: 5px solid black;

    // &.modalGC__section--container-success{
    //     border-color: var(--alert--semantic-success);
    // }

    // &.modalGC__section--container-warning{
    //     border-color: var(--alert--semantic-warning);
    // }

    // &.modalGC__section--container-error{
    //     border-color: var(--alert--semantic-error);
    // }

    // &.modalGC__section--container-info{
    //     border-color: var(--alert--semantic-info);
    // }
    .section__alert{
        padding: 24px;
    }
}

@keyframes fadeInDV {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes popUpDV {
    from {
        top: 100%;
    }
    to {
        top: 0;
    }
}

@keyframes popDownDV {
    from {
        top: 0;
    }
    to {
        top: 100%;
    }
}

@keyframes fadeOutDV {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}