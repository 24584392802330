@import "variables";
@import "./DesingSystem/buttons";

/**{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    position: relative;
    height: auto;
    line-height: 120%;
}

html{
    font-size: 62.5%;
    font-family: var(--font-family);
    text-wrap: pretty;
    scroll-behavior: smooth;
}

body{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    min-height: 100dvh;
    overflow-x: hidden;
}*/

html {
    font-size: 62.5%;
}

body {
    font-size: 1.4rem;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 600;
}

a{
    text-decoration: none;
    cursor: pointer;
}

button{
    cursor: pointer;
    border: none;
    background-color: transparent;
}

li{
   list-style: none;
}

address{
    font-style: normal;
}

summary::-webkit-details-marker {
    display:none;
}

.disabled__scroll{
    overflow-y: hidden;
}