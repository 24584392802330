//5-julio
$state-info-text: #31708f !default;
$state-info-bg: #d9edf7 !default;
$secondary-color: $state-info-text;
$secondary-light-color: $state-info-bg;
//5-julio

//BRAND
$brand-primary: #ea0074 !default;
$brand-secondary: #5c469c !default;
$brand-complementary: #57c6f4 !default; //Purpura

$svg-primary: "%23EA0074";
$svg-secondary: "%235C469C";
$svg-complementary: "%23A5A6F6";

//Variables para sobreescribir bootstrap
$white: #fff !default;
$black: #000 !default;
$gray-5: #fcfcfc;
$gray-10: #f2f2f2 !default;
$gray-100: #e5e5e5 !default;
$gray-200: #cccccc !default;
$gray-300: #b4b1b2 !default;
$gray-400: #999999 !default;
$gray-500: #808080 !default;
$gray-600: #666666 !default;
$gray-700: #4c4c4c !default;
$gray-800: #404040 !default;
$gray-900: #333333 !default;

$grays: () !default;
$grays: map-merge(
  (
    "10": $gray-10,
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900
  ),
  $grays
);

$pink: #ea0074 !default;
$pink-100: #ffe0ee !default;
$pink-300: #ff58a2 !default;
$pink-500: #ea0074 !default;
$pink-700: #c40061 !default;
$pink-900: #8f0047 !default;

$purple: #5c469c !default;
$purple-5: #eeebf6d6 !default;
$purple-10: #d9d3eb !default;
$purple-100: #e9e1ff !default;
$purple-300: #7967ae !default;
$purple-500: #5c469c !default;
$purple-700: #301b6b !default;
$purple-900: #1f1245 !default;

//States
$green: #489a00 !default;
$green-100: #e7f5d5 !default;
$green-300: #b8e383 !default;
$green-500: #489a00 !default;
$green-700: #357100 !default;
$green-900: #214600 !default;

$yellow: #fcb000 !default;
$yellow-10: #fffeef !default;
$yellow-100: #fef0d0 !default;
$yellow-300: #fdcc5c !default;
$yellow-500: #fcb000 !default;
$yellow-700: #c48100 !default;
$yellow-900: #8a6000 !default;

$blue: #4fc3f4 !default;
$blue-10: #f5faff !default;
$blue-100: #dff4fd !default;
$blue-300: #9fdef9 !default;
$blue-500: #4fc3f4 !default;
$blue-700: #00a0e4 !default;
$blue-900: #2c6b86 !default;

$red: #f62f0c !default;
$red-10: #fff7f5 !default;
$red-100: #fdd9d2 !default;
$red-300: #fa8d7a !default;
$red-500: #f62f0c !default;
$red-700: #ba0000 !default;
$red-900: #701606 !default;

//Complementarios
$indigo: #6610f2 !default;
$orange: #fd7e14 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;

//Social Media
$facebook: #4267b2 !default;
$google: #4285f4 !default;

$colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
  (
    "blue": $blue,
    "indigo": $indigo,
    "purple": $purple,
    "purple-light": $purple-10,
    "pink": $pink,
    "red": $red,
    "orange": $orange,
    "yellow": $yellow,
    "green": $green,
    "teal": $teal,
    "cyan": $cyan,
    "white": $white,
    "gray": $gray-700,
    "gray-dark": $gray-900
  ),
  $colors
);

$primary: $brand-primary !default;
$secondary: $brand-secondary !default;
$success: $green !default;
$info: $blue !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-10 !default;
$dark: $gray-900 !default;

$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "dark": $dark
  ),
  $theme-colors
);

$main-text: $gray-800;
$club-points: $brand-primary;
$success-main: $success;
$state-info-text: $info;
$error-main: $danger;

$blue: #0066cc;
$blue-10: #f5faff;
$blue-100: #b0dbff;
$blue-300: #6b9fff;
$blue-500: #0066cc;
$blue-700: #004e99;
$blue-900: #003d78;

//BRAND
$brand-primary: $blue;
$brand-secondary: $blue-700;
$brand-complementary: $gray-700; //Purpura

$bg-whiteLabel-bg-whiteLabel-primary: #003b98;
$border-whiteLabel-border-whiteLabel-primary: #003b98;
$bg-whiteLabel-bg-whiteLabel-primary-hover: #186bdf;
$border-whiteLabel-border-whiteLabel-primary-hover: #186bdf;
