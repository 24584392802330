//Ivan- 24-oct-2023**
//Se remaquetaron filtros del listado. Se ocupan estilos base para todos los sitios

.filtros__section{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: white;
}
.filtros__section--container{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}
.filtros__section--form-titulo{
    font-size: 16px;
}
.filtros__section--container-boton{
    font-size: 14px;
    padding: 5px 8px;
    border-radius: 20px;
    cursor: pointer;
    border: 1px solid #aeaeae;
}
.filtros__section--container-boton:hover{
    text-decoration: none;
}


.filtros__section--form{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.filtros__section--form-titulo{
    font-size: 16px;
}
.filtros__section--form-link{
    width: auto;
    height: auto;
    text-decoration: none;
    cursor: pointer;
}
.filtros__section--form-label{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
    font-size: 14px;
    cursor: pointer;
}
.filtros__section--form-input{
    margin: 1px 0px 0px 0px;
    padding: 0px;
    accent-color: #0275FF;
}


.filtros__section--toggle{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #aeaeae;
    border-radius: 20px;
}
.section__toggle--li{
    list-style: none;
    margin: 1px 1.5px;
}
.section__toggle--li-active{
    border-radius: 20px;
}
.section__toggle--li-link{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 4px 8px;
    text-decoration: none;
    font-size: 14px;
    cursor: pointer;
    text-decoration: none !important;
}
.section__toggle--li-active > .section__toggle--li-link{
    background-color: #eef7ff;
    border-radius: 20px;
    color: #6ea0e1;
}
@media screen and (max-width: 700px){
    .filtros__section--form{
        display: none;
    }
}
@media screen and (min-width: 700px){
    .filtros__section .modalSort,
    .filtros__section--container-boton{
        display: none;
    }
}


#hotel-filters .flex-wrap {
    flex-wrap: wrap !important;
}

#hotel-filters .d-flex {
    display: flex !important;
}

.flex-wrap {
    flex-wrap: wrap !important;
}

.d-flex {
    display: flex !important;
}
#hotel-filters p {
    margin: 0;
}
   
.position-relative {
    position: relative !important;
    margin-top: 15px;
}

#left h3.skeleton__element{
    background-color: transparent;
}

#left .form-control{
    padding-left: 12px;
}