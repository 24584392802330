@import "./styles";
@import "../Alerts/styles";
@import "../../Resumen-de-viajero/variables";


.modalGC__main--section {
    display: flex;
    flex-direction: column;
    gap: 16px;

}
.modalGC__form {
    display: flex;
    flex-direction: column;
}
.modalGC__form--container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    @media (min-width: 678px) {
        gap: 8px;
    }
}
.modalGC__form--container-elements{
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.modalGC__form--container-label {
    font-size: var(--text-size-sm);
    color: var(--neutral-color-2);
}
.modalGC__form--container-input {
    font-size: var(--text-size-sm);
    color: var(--neutral-color-2);
    flex-grow: 1;
    background-color: transparent;
    border-radius: 4px;
    padding: 4px 8px;
    border: 1px solid var(--neutral-color-7);

    &:disabled{
        background-color: var(--neutral-color-10);
        color: var(--neutral-color-5);
        cursor: not-allowed;
    }
}
.modalGC__form--container-edit{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 34px;
    height: 34px;
}

.modalGC__form--container-edit-icon {
    position: relative;
    display: flex;
    width: 24px;
    height: 24px;
    color: transparent;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IS0tIFVwbG9hZGVkIHRvOiBTVkcgUmVwbywgd3d3LnN2Z3JlcG8uY29tLCBHZW5lcmF0b3I6IFNWRyBSZXBvIE1peGVyIFRvb2xzIC0tPg0KPHN2ZyB3aWR0aD0iODAwcHgiIGhlaWdodD0iODAwcHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMjEuMTIxMyAyLjcwNzA1QzE5Ljk0OTcgMS41MzU0OCAxOC4wNTAzIDEuNTM1NDcgMTYuODc4NyAyLjcwNzA1TDE1LjE5ODkgNC4zODY4NUw3LjI5Mjg5IDEyLjI5MjhDNy4xNjQ3MyAxMi40MjEgNy4wNzM4MiAxMi41ODE2IDcuMDI5ODYgMTIuNzU3NEw2LjAyOTg2IDE2Ljc1NzRDNS45NDQ2NiAxNy4wOTgyIDYuMDQ0NTEgMTcuNDU4NyA2LjI5Mjg5IDE3LjcwN0M2LjU0MTI3IDE3Ljk1NTQgNi45MDE3NiAxOC4wNTUzIDcuMjQyNTQgMTcuOTcwMUwxMS4yNDI1IDE2Ljk3MDFDMTEuNDE4NCAxNi45MjYxIDExLjU3ODkgMTYuODM1MiAxMS43MDcxIDE2LjcwN0wxOS41NTU2IDguODU4NTdMMjEuMjkyOSA3LjEyMTI2QzIyLjQ2NDUgNS45NDk2OSAyMi40NjQ1IDQuMDUwMTkgMjEuMjkyOSAyLjg3ODYyTDIxLjEyMTMgMi43MDcwNVpNMTguMjkyOSA0LjEyMTI2QzE4LjY4MzQgMy43MzA3NCAxOS4zMTY2IDMuNzMwNzQgMTkuNzA3MSA0LjEyMTI2TDE5Ljg3ODcgNC4yOTI4M0MyMC4yNjkyIDQuNjgzMzYgMjAuMjY5MiA1LjMxNjUzIDE5Ljg3ODcgNS43MDcwNUwxOC44NjIyIDYuNzIzNTdMMTcuMzA2OCA1LjEwNzM4TDE4LjI5MjkgNC4xMjEyNlpNMTUuODkyMyA2LjUyMTg1TDE3LjQ0NzcgOC4xMzgwNEwxMC40ODg4IDE1LjA5N0w4LjM3NDM3IDE1LjYyNTZMOC45MDI5NiAxMy41MTEyTDE1Ljg5MjMgNi41MjE4NVpNNCA3Ljk5OTk0QzQgNy40NDc2NiA0LjQ0NzcyIDYuOTk5OTQgNSA2Ljk5OTk0SDEwQzEwLjU1MjMgNi45OTk5NCAxMSA2LjU1MjIzIDExIDUuOTk5OTRDMTEgNS40NDc2NiAxMC41NTIzIDQuOTk5OTQgMTAgNC45OTk5NEg1QzMuMzQzMTUgNC45OTk5NCAyIDYuMzQzMDkgMiA3Ljk5OTk0VjE4Ljk5OTlDMiAyMC42NTY4IDMuMzQzMTUgMjEuOTk5OSA1IDIxLjk5OTlIMTZDMTcuNjU2OSAyMS45OTk5IDE5IDIwLjY1NjggMTkgMTguOTk5OVYxMy45OTk5QzE5IDEzLjQ0NzcgMTguNTUyMyAxMi45OTk5IDE4IDEyLjk5OTlDMTcuNDQ3NyAxMi45OTk5IDE3IDEzLjQ0NzcgMTcgMTMuOTk5OVYxOC45OTk5QzE3IDE5LjU1MjIgMTYuNTUyMyAxOS45OTk5IDE2IDE5Ljk5OTlINUM0LjQ0NzcyIDE5Ljk5OTkgNCAxOS41NTIyIDQgMTguOTk5OVY3Ljk5OTk0WiIgZmlsbD0iIzAwMDAwMCIvPg0KPC9zdmc+);
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;
    transition: all 0.3s;
    opacity: .7;
}
.modalGC__form--container-edit-svg {
    width: 15px;
    height: 15px;
}

.modalGC__form--container-message-input{
    font-size: var(--text-size-sm);
    color: var(--neutral-color-3);
    margin-top: 8px;
}

.modalGC__container{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
}

.modalGC__container--button{
    width: fit-content;
    padding: 8px 20px;
    min-height: 44px;
    background-color: #013b98;
    font-size: var(--text-size-sm);
    color: white;
    cursor: pointer;
    border-radius: 4px;
}

.modalGC__section.modalGC__disabled{
    display: none;
}