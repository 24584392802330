$font-family-body:
  system-ui,
  -apple-system,
  "Segoe UI",
  Roboto,
  "Helvetica Neue",
  "Noto Sans",
  "Liberation Sans",
  Arial,
  sans-serif,
  "Apple Color Emoji",
  "Segoe UI Emoji",
  "Segoe UI Symbol",
  "Noto Color Emoji";

// Border radius
$radius-sm: 4px;
$radius-md: 8px;
$radius-lg: 12px;

//Variables de Box Shadow
$elevacion-1dp:
  0px 2px 1px rgba(0, 0, 0, 0.02),
  0px 1px 1px rgba(0, 0, 0, 0.14),
  0px 1px 3px rgba(0, 0, 0, 0.12);
$elevacion-2dp:
  0px 3px 1px rgba(0, 0, 0, 0.02),
  0px 2px 2px rgba(0, 0, 0, 0.14),
  0px 1px 5px rgba(0, 0, 0, 0.12);
$elevacion-4dp:
  0px 2px 4px rgba(0, 0, 0, 0.02),
  0px 4px 5px rgba(0, 0, 0, 0.14),
  0px 1px 10px rgba(0, 0, 0, 0.12);
$elevacion-6dp:
  0px 3px 5px rgba(0, 0, 0, 0.02),
  0px 6px 10px rgba(0, 0, 0, 0.14),
  0px 1px 18px rgba(0, 0, 0, 0.12);
$elevacion-8dp:
  0px 5px 5px rgba(0, 0, 0, 0.02),
  0px 8px 10px rgba(0, 0, 0, 0.14),
  0px 3px 14px rgba(0, 0, 0, 0.12);
