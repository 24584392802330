.modalUS__skeleton--details{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    width: 130px;
    height: 20px;
    margin: 16px 24px 0px 0px;
}

.modalUS__skeleton--article{
    position: relative;
    display: flex;
    scroll-snap-align: center;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-width: 300px;
    min-height: 95%;
    max-width: 330px;
    border-radius: 4px;
    border: 2px solid #C1BFC7;
    border-top: 7px solid #C1BFC7;


    @media (max-width: 768px) {
        max-width: 100%;
        margin-top: 15px;

        &:nth-child(1){
            margin-top: 5px;
        }
    }
}

.modalUS__skeleton{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalUS__skeleton::before{
    position: absolute;
    content: '';
    width: 100%;
    height: 60%;
    background-color: #C1BFC7;
    border-radius: 20px;
}

.modalUS__skeleton::after{
    position: absolute;
    content: '';
    width: 100%;
    height: 60%;
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 50%, rgba(255,255,255,0) 100%);
    animation: skeleton 1.5s infinite;
    background-size: 200% 100%;
    background-repeat: no-repeat;
    background-position: 100% 0;
    border-radius: 10px;
    z-index: 1;
}


.modalUS__skeleton--h3{
    width: 100px;
    height: 40px;
}

.modalUS__skeleton--h4{
    width: 60%;
    height: 30px;
}

.modalUS__skeleton--li{
    width: 90%;
    height: 20px;
}

.modalUS__skeleton--h3::before{
    background-color: #C1BFC7;
}

.modalUS__skeleton--details{
    .modalUS__main--details-toggle-icon{
        opacity: 0;
    }
    
    &::before{
        background-color: var(--upsell-text-color-titulo-skeleton);
    } 
}

.modalUS__skeleton--h4::before,
.modalUS__skeleton--li::before,
.modalUS__skeleton--price-h2::before,
.modalUS__skeleton--price-p::before{
    background-color: #D9D9D9;
}

.modalUS__skeleton--price-h2{
    width: 180px;
    height: 30px;
}
.modalUS__skeleton--price-p{
    width: 100px;
    height: 20px;
}

.modalUS__skeleton--article-section-price{
    background-color: var(--upsell-background-color-2);
}
.modalUS__skeleton--article-container-price-description{
    width: 110px;
    height: 50px;

    &::before{
        background-color: var(--upsell-text-color-titulo-skeleton);
    }    
}

.modalUS__article--container-price-title{
    & .modalUS__skeleton--article-container-price-title-span{
        width: 100px;
        height: 20px;
    
        &:nth-child(2){
            width: 70px;
        }

        &::before{
            background-color: var(--upsell-text-color-titulo-skeleton);
        }
    }
}


@keyframes skeleton {
    0% {
        background-position: 150% 0;
    }
    100% {
        background-position: -100% 0;
    }
}



.modalUS__skeleton--price-button{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    color: white;
    font-size: 1.6rem;
    background-color: var(--upsell-boton-background-1);
    height: 60px;
    border-radius: 4px;
    border: none;

    @media (min-width: 800px){
        width: 200px;
        height: 60px;
        margin-right: 0px;
        font-size: 1.8rem;
    }

    &::after{
        border-radius: 4px !important;
    }
}

.modalUS__skeleton--price-button::before{
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
}

.modalUS__skeleton--button-slide::after,
.modalUS__skeleton--price-button::after{
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 50%, rgba(255,255,255,0) 100%);
    animation: skeleton 1.5s infinite;
    background-size: 200% 100%;
    background-repeat: no-repeat;
    background-position: 100% 0;
    border-radius: 10px;
    z-index: 1;
}


.modalUS__main--button{
    display: none;

    @media (min-width: 800px){
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: none;
        background-color: var(--upsell-boton-control-background);
        z-index: 10;
        cursor: pointer;
        top: calc(50% - 20px);
    }
}
.modalUS__main--button-left{
    left: 10px;
    padding-right: 2px;
}
.modalUS__main--button-right{
    right: 10px;
    padding-left: 2px;
}