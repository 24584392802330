.modalUS__article--section-list{
    width: 100%;
    flex-grow: 1;
    overflow: hidden;
    overflow-y: scroll;
    overscroll-behavior-y: contain;
    scrollbar-width: thin;
}

.modalUS__article--ul {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: auto;
    list-style: none;
}

.modalUS__article--li {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: auto;
    padding: 8px 16px;

    &:last-child{
        padding-bottom: 100px;
    }
}

.modalUS__article--li-icon{
    position: relative;
    display: grid;
    place-items: center;
}

.modalUS__article--details{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: auto;
}
.modalUS__article--details-title{
    font-size: 1.5rem;
    font-weight: 600;
    font-family: var(--font-family);
    color: var(--upsell-text-color-titulo-3);
}
.modalUS__article--details-description {
    font-size: 1.4rem;
    font-weight: 400;
    font-family: var(--font-family);
    color: var(--upsell-text-color-1);
}