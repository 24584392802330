//Ivan- 30-nov-2023**
// Estilos para la vista de listado de hoteles

@media screen and (min-width: 515px){
    #hotelCardItem .card-hotel .hotel-item{
        display: grid;
        align-items: start;
        grid-template-columns: 1fr 1.5fr .69fr;
        grid-template-areas: "galeria informacion precio";
        width: 100%;
        height: auto;
    }
    
    #hotelCardItem .card-hotel .hotel-item > div{
        width: 100%;
        position: relative;
    }

    #hotelCardItem .card-hotel .hotel-item .card-hotel-galery{
        grid-area: galeria;
    }
    #hotelCardItem .card-hotel .hotel-item .card-hotel-info{
        grid-area: informacion;
    }
    #hotelCardItem .card-hotel .hotel-item .card-hotel-price{
        grid-area: precio;
    }
    
    #hotelCardItem .card-hotel .hotel-item .card-hotel-galery .carousel-item img.hotImg {
        object-fit: cover;
    }    
}