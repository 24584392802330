
$basic: #be6dff;
$standard: #00c8ff;
$plus: #ff0091;
$extra: #63c;

.modalUS__article{
    &.modalUS__article--p5-basic{
        border-color: $basic;
    
        & .modalUS__article--title{
            color: $basic;
        }
    }
    &.modalUS__article--p5-standard{
        border-color: $standard;
    
        & .modalUS__article--title{
            color: $standard;
        }
    }
    &.modalUS__article--p5-plus{
        border-color: $plus;
    
        & .modalUS__article--title{
            color: $plus;
        }
    }

    &.modalUS__article--p5-extra{
        border-color: $extra;
    
        & .modalUS__article--title{
            color: $extra;
        }
    }


    &.modalUS__article--selected{
        &.modalUS__article--p5-basic{
            .modalUS__article--container-price{
                background-color: $basic;
            }

            & .modalUS__article--checkbox-custom{
                background-color: $basic;
        
                &::after{
                    background-color: $basic;
                }
            }
        }

        &.modalUS__article--p5-standard{
            .modalUS__article--container-price{
                background-color: $standard;
            }

            & .modalUS__article--checkbox-custom{
                background-color: $standard;
        
                &::after{
                    background-color: $standard;
                }
            }
        }

        &.modalUS__article--p5-plus{
            .modalUS__article--container-price{
                background-color: $plus;
            }

            & .modalUS__article--checkbox-custom{
                background-color: $plus;
        
                &::after{
                    background-color: $plus;
                }
            }
        }

        &.modalUS__article--p5-extra{
            .modalUS__article--container-price{
                background-color: $extra;
            }

            & .modalUS__article--checkbox-custom{
                background-color: $extra;
        
                &::after{
                    background-color: $extra;
                }
            }
        }
    }
}