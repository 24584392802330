//15-noviembre
@font-face {
    font-family: "#{$icon-font-family}";
    src: url(https://b2b2c.cdnpt.com/recursos/fonts/pth-icon.eot);
    src:
        url(https://b2b2c.cdnpt.com/recursos/fonts/pth-icon.eot?#iefix) format("embedded-opentype"),
        url(https://b2b2c.cdnpt.com/recursos/fonts/pth-icon.woff) format("woff"),
        url(https://b2b2c.cdnpt.com/recursos/fonts/pth-icon.svg) format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.font-icons {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "#{$icon-font-family}" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icons-account-balance {
    &:before {
        content: $icons-account-balance;
    }
}
.icons-account-circle {
    &:before {
        content: $icons-account-circle;
    }
}
.icons-add {
    &:before {
        content: $icons-add;
    }
}
.icons-add-circle {
    &:before {
        content: $icons-add-circle;
    }
}
.icons-adults-only {
    &:before {
        content: $icons-adults-only;
    }
}
.icons-adults-only-outline {
    &:before {
        content: $icons-adults-only-outline;
    }
}
.icons-airline-seat {
    &:before {
        content: $icons-airline-seat;
    }
}
.icons-airplane-up {
    &:before {
        content: $icons-airplane-up;
    }
}
.icons-airplane-up-outline {
    &:before {
        content: $icons-airplane-up-outline;
    }
}
.icons-angle-down {
    &:before {
        content: $icons-angle-down;
    }
}
.icons-angle-left {
    &:before {
        content: $icons-angle-left;
    }
}
.icons-angle-right {
    &:before {
        content: $icons-angle-right;
    }
}
.icons-angle-up {
    &:before {
        content: $icons-angle-up;
    }
}
.icons-arrow-back {
    &:before {
        content: $icons-arrow-back;
    }
}
.icons-arrow-downward {
    &:before {
        content: $icons-arrow-downward;
    }
}
.icons-arrow-drop-down {
    &:before {
        content: $icons-arrow-drop-down;
    }
}
.icons-arrow-drop-up {
    &:before {
        content: $icons-arrow-drop-up;
    }
}
.icons-arrow-right {
    &:before {
        content: $icons-arrow-right;
    }
}
.icons-arrow-up-arrow-down {
    &:before {
        content: $icons-arrow-up-arrow-down;
    }
}
.icons-arrow-upward {
    &:before {
        content: $icons-arrow-upward;
    }
}
.icons-attach-money {
    &:before {
        content: $icons-attach-money;
    }
}
.icons-b-bradescard {
    &:before {
        content: $icons-b-bradescard;
    }
}
.icons-b-disney {
    &:before {
        content: $icons-b-disney;
    }
}
.icons-b-disney-outline {
    &:before {
        content: $icons-b-disney-outline;
    }
}
.icons-b-facebook {
    &:before {
        content: $icons-b-facebook;
    }
}
.icons-b-facebook-square {
    &:before {
        content: $icons-b-facebook-square;
    }
}
.icons-b-google-plus {
    &:before {
        content: $icons-b-google-plus;
    }
}
.icons-b-instagram {
    &:before {
        content: $icons-b-instagram;
    }
}
.icons-b-linkedin {
    &:before {
        content: $icons-b-linkedin;
    }
}
.icons-b-linkedin-square {
    &:before {
        content: $icons-b-linkedin-square;
    }
}
.icons-b-pinterest {
    &:before {
        content: $icons-b-pinterest;
    }
}
.icons-b-pricetravel {
    &:before {
        content: $icons-b-pricetravel;
    }
}
.icons-b-tiktok {
    &:before {
        content: $icons-b-tiktok;
    }
}
.icons-b-tiktok-outline {
    &:before {
        content: $icons-b-tiktok-outline;
    }
}
.icons-b-travelinn {
    &:before {
        content: $icons-b-travelinn;
    }
}
.icons-b-tripadvisor {
    &:before {
        content: $icons-b-tripadvisor;
    }
}
.icons-b-twitter {
    &:before {
        content: $icons-b-twitter;
    }
}
.icons-b-twitter-square {
    &:before {
        content: $icons-b-twitter-square;
    }
}
.icons-b-twitter1 {
    &:before {
        content: $icons-b-twitter1;
    }
}
.icons-b-whatsapp {
    &:before {
        content: $icons-b-whatsapp;
    }
}
.icons-b-xcaret {
    &:before {
        content: $icons-b-xcaret;
    }
}
.icons-b-xcaret-color .path1 {
    &:before {
        content: $icons-b-xcaret-color-path1;
        color: rgb(255, 129, 0);
    }
}
.icons-b-xcaret-color .path2 {
    &:before {
        content: $icons-b-xcaret-color-path2;
        margin-left: -1.0576171875em;
        color: rgb(255, 48, 153);
    }
}
.icons-b-xcaret-color .path3 {
    &:before {
        content: $icons-b-xcaret-color-path3;
        margin-left: -1.0576171875em;
        color: rgb(0, 84, 156);
    }
}
.icons-b-xcaret-color .path4 {
    &:before {
        content: $icons-b-xcaret-color-path4;
        margin-left: -1.0576171875em;
        color: rgb(168, 210, 44);
    }
}
.icons-b-xcaret-color .path5 {
    &:before {
        content: $icons-b-xcaret-color-path5;
        margin-left: -1.0576171875em;
        color: rgb(255, 202, 0);
    }
}
.icons-b-xcaret-color .path6 {
    &:before {
        content: $icons-b-xcaret-color-path6;
        margin-left: -1.0576171875em;
        color: rgb(0, 162, 53);
    }
}
.icons-b-xcaret-color .path7 {
    &:before {
        content: $icons-b-xcaret-color-path7;
        margin-left: -1.0576171875em;
        color: rgb(178, 47, 204);
    }
}
.icons-b-xcaret-color .path8 {
    &:before {
        content: $icons-b-xcaret-color-path8;
        margin-left: -1.0576171875em;
        color: rgb(0, 206, 246);
    }
}
.icons-b-youtube {
    &:before {
        content: $icons-b-youtube;
    }
}
.icons-baby {
    &:before {
        content: $icons-baby;
    }
}
.icons-baby-outline {
    &:before {
        content: $icons-baby-outline;
    }
}
.icons-balcony {
    &:before {
        content: $icons-balcony;
    }
}
.icons-balcony-2 {
    &:before {
        content: $icons-balcony-2;
    }
}
.icons-balcony-2-outline {
    &:before {
        content: $icons-balcony-2-outline;
    }
}
.icons-bathtub {
    &:before {
        content: $icons-bathtub;
    }
}
.icons-bathtub-outline {
    &:before {
        content: $icons-bathtub-outline;
    }
}
.icons-beach {
    &:before {
        content: $icons-beach;
    }
}
.icons-beach-club {
    &:before {
        content: $icons-beach-club;
    }
}
.icons-bed {
    &:before {
        content: $icons-bed;
    }
}
.icons-bed-outline {
    &:before {
        content: $icons-bed-outline;
    }
}
.icons-bed1 {
    &:before {
        content: $icons-bed1;
    }
}
.icons-bell {
    &:before {
        content: $icons-bell;
    }
}
.icons-bell-concierge {
    &:before {
        content: $icons-bell-concierge;
    }
}
.icons-bell-concierge-outline {
    &:before {
        content: $icons-bell-concierge-outline;
    }
}
.icons-bell-outline {
    &:before {
        content: $icons-bell-outline;
    }
}
.icons-book {
    &:before {
        content: $icons-book;
    }
}
.icons-book-outline {
    &:before {
        content: $icons-book-outline;
    }
}
.icons-broken-image {
    &:before {
        content: $icons-broken-image;
    }
}
.icons-bus {
    &:before {
        content: $icons-bus;
    }
}
.icons-bus-front {
    &:before {
        content: $icons-bus-front;
    }
}
.icons-bus-front-outline {
    &:before {
        content: $icons-bus-front-outline;
    }
}
.icons-bus-outline {
    &:before {
        content: $icons-bus-outline;
    }
}
.icons-calendar {
    &:before {
        content: $icons-calendar;
    }
}
.icons-calendar-depart {
    &:before {
        content: $icons-calendar-depart;
    }
}
.icons-calendar-outline {
    &:before {
        content: $icons-calendar-outline;
    }
}
.icons-calendar-return {
    &:before {
        content: $icons-calendar-return;
    }
}
.icons-calendar-today {
    &:before {
        content: $icons-calendar-today;
    }
}
.icons-camera {
    &:before {
        content: $icons-camera;
    }
}
.icons-camera-cctv {
    &:before {
        content: $icons-camera-cctv;
    }
}
.icons-camera-cctv-outline {
    &:before {
        content: $icons-camera-cctv-outline;
    }
}
.icons-camera-outline {
    &:before {
        content: $icons-camera-outline;
    }
}
.icons-camera-retro {
    &:before {
        content: $icons-camera-retro;
    }
}
.icons-cancel {
    &:before {
        content: $icons-cancel;
    }
}
.icons-cancel-circle {
    &:before {
        content: $icons-cancel-circle;
    }
}
.icons-car {
    &:before {
        content: $icons-car;
    }
}
.icons-car-outline {
    &:before {
        content: $icons-car-outline;
    }
}
.icons-car-side {
    &:before {
        content: $icons-car-side;
    }
}
.icons-carry-on-bag {
    &:before {
        content: $icons-carry-on-bag;
    }
}
.icons-carry-on-bag-off {
    &:before {
        content: $icons-carry-on-bag-off;
    }
}
.icons-cash {
    &:before {
        content: $icons-cash;
    }
}
.icons-cash-outline {
    &:before {
        content: $icons-cash-outline;
    }
}
.icons-cash-register {
    &:before {
        content: $icons-cash-register;
    }
}
.icons-cash-register-outline {
    &:before {
        content: $icons-cash-register-outline;
    }
}
.icons-check {
    &:before {
        content: $icons-check;
    }
}
.icons-check-1 {
    &:before {
        content: $icons-check-1;
    }
}
.icons-check-circle {
    &:before {
        content: $icons-check-circle;
    }
}
.icons-check-mark-circle {
    &:before {
        content: $icons-check-mark-circle;
    }
}
.icons-check-mark-circle-outline {
    &:before {
        content: $icons-check-mark-circle-outline;
    }
}
.icons-check1 {
    &:before {
        content: $icons-check1;
    }
}
.icons-chevron-down {
    &:before {
        content: $icons-chevron-down;
    }
}
.icons-chevron-left {
    &:before {
        content: $icons-chevron-left;
    }
}
.icons-chevron-right {
    &:before {
        content: $icons-chevron-right;
    }
}
.icons-chevron-up {
    &:before {
        content: $icons-chevron-up;
    }
}
.icons-circle-plus {
    &:before {
        content: $icons-circle-plus;
    }
}
.icons-circle-user {
    &:before {
        content: $icons-circle-user;
    }
}
.icons-circuitos {
    &:before {
        content: $icons-circuitos;
    }
}
.icons-circuitos-2 {
    &:before {
        content: $icons-circuitos-2;
    }
}
.icons-city {
    &:before {
        content: $icons-city;
    }
}
.icons-city-outline {
    &:before {
        content: $icons-city-outline;
    }
}
.icons-clock {
    &:before {
        content: $icons-clock;
    }
}
.icons-clock-outline {
    &:before {
        content: $icons-clock-outline;
    }
}
.icons-clock-rotate-left {
    &:before {
        content: $icons-clock-rotate-left;
    }
}
.icons-close {
    &:before {
        content: $icons-close;
    }
}
.icons-coffee-machine {
    &:before {
        content: $icons-coffee-machine;
    }
}
.icons-coffee-machine-outline {
    &:before {
        content: $icons-coffee-machine-outline;
    }
}
.icons-compass {
    &:before {
        content: $icons-compass;
    }
}
.icons-couch {
    &:before {
        content: $icons-couch;
    }
}
.icons-couch-outline {
    &:before {
        content: $icons-couch-outline;
    }
}
.icons-credit-card {
    &:before {
        content: $icons-credit-card;
    }
}
.icons-credit-card-outline {
    &:before {
        content: $icons-credit-card-outline;
    }
}
.icons-credit-card1 {
    &:before {
        content: $icons-credit-card1;
    }
}
.icons-cruise {
    &:before {
        content: $icons-cruise;
    }
}
.icons-cup {
    &:before {
        content: $icons-cup;
    }
}
.icons-cup-outline {
    &:before {
        content: $icons-cup-outline;
    }
}
.icons-departure {
    &:before {
        content: $icons-departure;
    }
}
.icons-departure-outline {
    &:before {
        content: $icons-departure-outline;
    }
}
.icons-discount {
    &:before {
        content: $icons-discount;
    }
}
.icons-discount-outline {
    &:before {
        content: $icons-discount-outline;
    }
}
.icons-disney {
    &:before {
        content: $icons-disney;
    }
}
.icons-disney-outline {
    &:before {
        content: $icons-disney-outline;
    }
}
.icons-door-hanger {
    &:before {
        content: $icons-door-hanger;
    }
}
.icons-door-hanger-outline {
    &:before {
        content: $icons-door-hanger-outline;
    }
}
.icons-dropdown {
    &:before {
        content: $icons-dropdown;
    }
}
.icons-dropdown-outline {
    &:before {
        content: $icons-dropdown-outline;
    }
}
.icons-earth-americas-regular {
    &:before {
        content: $icons-earth-americas-regular;
    }
}
.icons-edit {
    &:before {
        content: $icons-edit;
    }
}
.icons-edit-outline {
    &:before {
        content: $icons-edit-outline;
    }
}
.icons-email {
    &:before {
        content: $icons-email;
    }
}
.icons-envelope {
    &:before {
        content: $icons-envelope;
    }
}
.icons-envelope-outline {
    &:before {
        content: $icons-envelope-outline;
    }
}
.icons-error {
    &:before {
        content: $icons-error;
    }
}
.icons-exclamation-triangle {
    &:before {
        content: $icons-exclamation-triangle;
    }
}
.icons-exclamation-triangle-outline {
    &:before {
        content: $icons-exclamation-triangle-outline;
    }
}
.icons-expand-less {
    &:before {
        content: $icons-expand-less;
    }
}
.icons-expand-more {
    &:before {
        content: $icons-expand-more;
    }
}
.icons-explore {
    &:before {
        content: $icons-explore;
    }
}
.icons-family {
    &:before {
        content: $icons-family;
    }
}
.icons-family-outline {
    &:before {
        content: $icons-family-outline;
    }
}
.icons-feedback {
    &:before {
        content: $icons-feedback;
    }
}
.icons-file {
    &:before {
        content: $icons-file;
    }
}
.icons-filter-list {
    &:before {
        content: $icons-filter-list;
    }
}
.icons-flag-checkered {
    &:before {
        content: $icons-flag-checkered;
    }
}
.icons-flame {
    &:before {
        content: $icons-flame;
    }
}
.icons-flame-outline {
    &:before {
        content: $icons-flame-outline;
    }
}
.icons-fligh-takeoff {
    &:before {
        content: $icons-fligh-takeoff;
    }
}
.icons-flight-land {
    &:before {
        content: $icons-flight-land;
    }
}
.icons-gift {
    &:before {
        content: $icons-gift;
    }
}
.icons-gift-outline {
    &:before {
        content: $icons-gift-outline;
    }
}
.icons-glass-martini {
    &:before {
        content: $icons-glass-martini;
    }
}
.icons-glass-martini-citrus {
    &:before {
        content: $icons-glass-martini-citrus;
    }
}
.icons-glass-martini-citrus-outline {
    &:before {
        content: $icons-glass-martini-citrus-outline;
    }
}
.icons-glass-martini-outline {
    &:before {
        content: $icons-glass-martini-outline;
    }
}
.icons-globe-north-america {
    &:before {
        content: $icons-globe-north-america;
    }
}
.icons-globe-outline-north-america {
    &:before {
        content: $icons-globe-outline-north-america;
    }
}
.icons-golf {
    &:before {
        content: $icons-golf;
    }
}
.icons-grid {
    &:before {
        content: $icons-grid;
    }
}
.icons-grid1 {
    &:before {
        content: $icons-grid1;
    }
}
.icons-guarantee-seal {
    &:before {
        content: $icons-guarantee-seal;
    }
}
.icons-guarantee-seal-outline {
    &:before {
        content: $icons-guarantee-seal-outline;
    }
}
.icons-guests {
    &:before {
        content: $icons-guests;
    }
}
.icons-hair-dryer {
    &:before {
        content: $icons-hair-dryer;
    }
}
.icons-hair-dryer-outline {
    &:before {
        content: $icons-hair-dryer-outline;
    }
}
.icons-hamburguer-menu {
    &:before {
        content: $icons-hamburguer-menu;
    }
}
.icons-happy-face {
    &:before {
        content: $icons-happy-face;
    }
}
.icons-happy-face-outline {
    &:before {
        content: $icons-happy-face-outline;
    }
}
.icons-hashtag {
    &:before {
        content: $icons-hashtag;
    }
}
.icons-heart {
    &:before {
        content: $icons-heart;
    }
}
.icons-heart-outline {
    &:before {
        content: $icons-heart-outline;
    }
}
.icons-home {
    &:before {
        content: $icons-home;
    }
}
.icons-home-outline {
    &:before {
        content: $icons-home-outline;
    }
}
.icons-hotel {
    &:before {
        content: $icons-hotel;
    }
}
.icons-id-card {
    &:before {
        content: $icons-id-card;
    }
}
.icons-id-card-clip {
    &:before {
        content: $icons-id-card-clip;
    }
}
.icons-id-card-clip-outline {
    &:before {
        content: $icons-id-card-clip-outline;
    }
}
.icons-id-card-outline {
    &:before {
        content: $icons-id-card-outline;
    }
}
.icons-info {
    &:before {
        content: $icons-info;
    }
}
.icons-info-outline {
    &:before {
        content: $icons-info-outline;
    }
}
.icons-info1 {
    &:before {
        content: $icons-info1;
    }
}
.icons-iron {
    &:before {
        content: $icons-iron;
    }
}
.icons-iron-1 {
    &:before {
        content: $icons-iron-1;
    }
}
.icons-jacuzzi {
    &:before {
        content: $icons-jacuzzi;
    }
}
.icons-jacuzzi-outline {
    &:before {
        content: $icons-jacuzzi-outline;
    }
}
.icons-keyboard-return {
    &:before {
        content: $icons-keyboard-return;
    }
}
.icons-keyboard-tab {
    &:before {
        content: $icons-keyboard-tab;
    }
}
.icons-kids-club {
    &:before {
        content: $icons-kids-club;
    }
}
.icons-kids-pool {
    &:before {
        content: $icons-kids-pool;
    }
}
.icons-kitchen {
    &:before {
        content: $icons-kitchen;
    }
}
.icons-kitchen-outline {
    &:before {
        content: $icons-kitchen-outline;
    }
}
.icons-label-left {
    &:before {
        content: $icons-label-left;
    }
}
.icons-label-right {
    &:before {
        content: $icons-label-right;
    }
}
.icons-laptop {
    &:before {
        content: $icons-laptop;
    }
}
.icons-list {
    &:before {
        content: $icons-list;
    }
}
.icons-local-dining {
    &:before {
        content: $icons-local-dining;
    }
}
.icons-location {
    &:before {
        content: $icons-location;
    }
}
.icons-location-outline {
    &:before {
        content: $icons-location-outline;
    }
}
.icons-location1 {
    &:before {
        content: $icons-location1;
    }
}
.icons-lock {
    &:before {
        content: $icons-lock;
    }
}
.icons-lock-outline {
    &:before {
        content: $icons-lock-outline;
    }
}
.icons-lock1 {
    &:before {
        content: $icons-lock1;
    }
}
.icons-magic-magic-wand-streamline {
    &:before {
        content: $icons-magic-magic-wand-streamline;
    }
}
.icons-magic-magic-wand-streamline-outline {
    &:before {
        content: $icons-magic-magic-wand-streamline-outline;
    }
}
.icons-mail {
    &:before {
        content: $icons-mail;
    }
}
.icons-mail-outline {
    &:before {
        content: $icons-mail-outline;
    }
}
.icons-map-location {
    &:before {
        content: $icons-map-location;
    }
}
.icons-megaphone {
    &:before {
        content: $icons-megaphone;
    }
}
.icons-megaphone-outline {
    &:before {
        content: $icons-megaphone-outline;
    }
}
.icons-menu {
    &:before {
        content: $icons-menu;
    }
}
.icons-mobile {
    &:before {
        content: $icons-mobile;
    }
}
.icons-mobile-outline {
    &:before {
        content: $icons-mobile-outline;
    }
}
.icons-money {
    &:before {
        content: $icons-money;
    }
}
.icons-money-outline {
    &:before {
        content: $icons-money-outline;
    }
}
.icons-money-sign {
    &:before {
        content: $icons-money-sign;
    }
}
.icons-monument {
    &:before {
        content: $icons-monument;
    }
}
.icons-monument-outline {
    &:before {
        content: $icons-monument-outline;
    }
}
.icons-neutral-face {
    &:before {
        content: $icons-neutral-face;
    }
}
.icons-neutral-face-outline {
    &:before {
        content: $icons-neutral-face-outline;
    }
}
.icons-next {
    &:before {
        content: $icons-next;
    }
}
.icons-next-outline {
    &:before {
        content: $icons-next-outline;
    }
}
.icons-no-refunds {
    &:before {
        content: $icons-no-refunds;
    }
}
.icons-no-refunds-1 {
    &:before {
        content: $icons-no-refunds-1;
    }
}
.icons-notifications {
    &:before {
        content: $icons-notifications;
    }
}
.icons-opinion-empty {
    &:before {
        content: $icons-opinion-empty;
    }
}
.icons-opinion-half {
    &:before {
        content: $icons-opinion-half;
    }
}
.icons-opinion-half1 {
    &:before {
        content: $icons-opinion-half1;
    }
}
.icons-opinion-half2 {
    &:before {
        content: $icons-opinion-half2;
    }
}
.icons-opinion1 {
    &:before {
        content: $icons-opinion1;
    }
}
.icons-opinion2 {
    &:before {
        content: $icons-opinion2;
    }
}
.icons-opinion3 {
    &:before {
        content: $icons-opinion3;
    }
}
.icons-pack {
    &:before {
        content: $icons-pack;
    }
}
.icons-pack-outline-outline {
    &:before {
        content: $icons-pack-outline-outline;
    }
}
.icons-parking {
    &:before {
        content: $icons-parking;
    }
}
.icons-parking-outline {
    &:before {
        content: $icons-parking-outline;
    }
}
.icons-pencil {
    &:before {
        content: $icons-pencil;
    }
}
.icons-pencil-outline {
    &:before {
        content: $icons-pencil-outline;
    }
}
.icons-people {
    &:before {
        content: $icons-people;
    }
}
.icons-people-outline {
    &:before {
        content: $icons-people-outline;
    }
}
.icons-person {
    &:before {
        content: $icons-person;
    }
}
.icons-personal-bag {
    &:before {
        content: $icons-personal-bag;
    }
}
.icons-pet {
    &:before {
        content: $icons-pet;
    }
}
.icons-pet-outline {
    &:before {
        content: $icons-pet-outline;
    }
}
.icons-phone {
    &:before {
        content: $icons-phone;
    }
}
.icons-phone-1 {
    &:before {
        content: $icons-phone-1;
    }
}
.icons-phone-outline {
    &:before {
        content: $icons-phone-outline;
    }
}
.icons-phone-outline-1 {
    &:before {
        content: $icons-phone-outline-1;
    }
}
.icons-phone1 {
    &:before {
        content: $icons-phone1;
    }
}
.icons-photo-camera {
    &:before {
        content: $icons-photo-camera;
    }
}
.icons-picture {
    &:before {
        content: $icons-picture;
    }
}
.icons-picture-outline {
    &:before {
        content: $icons-picture-outline;
    }
}
.icons-piggy-bank {
    &:before {
        content: $icons-piggy-bank;
    }
}
.icons-piggy-bank-outline {
    &:before {
        content: $icons-piggy-bank-outline;
    }
}
.icons-place {
    &:before {
        content: $icons-place;
    }
}
.icons-plane {
    &:before {
        content: $icons-plane;
    }
}
.icons-plane-airport {
    &:before {
        content: $icons-plane-airport;
    }
}
.icons-plane-airport-outline {
    &:before {
        content: $icons-plane-airport-outline;
    }
}
.icons-plane-arrival {
    &:before {
        content: $icons-plane-arrival;
    }
}
.icons-plane-arrival-outline {
    &:before {
        content: $icons-plane-arrival-outline;
    }
}
.icons-plane-departure {
    &:before {
        content: $icons-plane-departure;
    }
}
.icons-plane-departure-outline {
    &:before {
        content: $icons-plane-departure-outline;
    }
}
.icons-plane-engine {
    &:before {
        content: $icons-plane-engine;
    }
}
.icons-plane-engine-outline {
    &:before {
        content: $icons-plane-engine-outline;
    }
}
.icons-plane-outline {
    &:before {
        content: $icons-plane-outline;
    }
}
.icons-plate-utensils {
    &:before {
        content: $icons-plate-utensils;
    }
}
.icons-play-circle {
    &:before {
        content: $icons-play-circle;
    }
}
.icons-pool {
    &:before {
        content: $icons-pool;
    }
}
.icons-present-gift {
    &:before {
        content: $icons-present-gift;
    }
}
.icons-present-gift-outline {
    &:before {
        content: $icons-present-gift-outline;
    }
}
.icons-print {
    &:before {
        content: $icons-print;
    }
}
.icons-print-outline {
    &:before {
        content: $icons-print-outline;
    }
}
.icons-receipt {
    &:before {
        content: $icons-receipt;
    }
}
.icons-receipt-outline {
    &:before {
        content: $icons-receipt-outline;
    }
}
.icons-refresh {
    &:before {
        content: $icons-refresh;
    }
}
.icons-refresh-outline {
    &:before {
        content: $icons-refresh-outline;
    }
}
.icons-remove {
    &:before {
        content: $icons-remove;
    }
}
.icons-remove-circle {
    &:before {
        content: $icons-remove-circle;
    }
}
.icons-remove1 {
    &:before {
        content: $icons-remove1;
    }
}
.icons-romance {
    &:before {
        content: $icons-romance;
    }
}
.icons-romance-outline {
    &:before {
        content: $icons-romance-outline;
    }
}
.icons-sad-face {
    &:before {
        content: $icons-sad-face;
    }
}
.icons-sad-face-outline {
    &:before {
        content: $icons-sad-face-outline;
    }
}
.icons-save {
    &:before {
        content: $icons-save;
    }
}
.icons-save-box {
    &:before {
        content: $icons-save-box;
    }
}
.icons-save-box-outline {
    &:before {
        content: $icons-save-box-outline;
    }
}
.icons-save-outline {
    &:before {
        content: $icons-save-outline;
    }
}
.icons-save1 {
    &:before {
        content: $icons-save1;
    }
}
.icons-search {
    &:before {
        content: $icons-search;
    }
}
.icons-search-outline {
    &:before {
        content: $icons-search-outline;
    }
}
.icons-search1 {
    &:before {
        content: $icons-search1;
    }
}
.icons-share-nodes {
    &:before {
        content: $icons-share-nodes;
    }
}
.icons-shield {
    &:before {
        content: $icons-shield;
    }
}
.icons-shield-halved {
    &:before {
        content: $icons-shield-halved;
    }
}
.icons-ship {
    &:before {
        content: $icons-ship;
    }
}
.icons-snow {
    &:before {
        content: $icons-snow;
    }
}
.icons-spa {
    &:before {
        content: $icons-spa;
    }
}
.icons-spa-outline {
    &:before {
        content: $icons-spa-outline;
    }
}
.icons-star {
    &:before {
        content: $icons-star;
    }
}
.icons-star-half {
    &:before {
        content: $icons-star-half;
    }
}
.icons-star-outline {
    &:before {
        content: $icons-star-outline;
    }
}
.icons-store {
    &:before {
        content: $icons-store;
    }
}
.icons-store-outline {
    &:before {
        content: $icons-store-outline;
    }
}
.icons-subdirectory-arrow-right {
    &:before {
        content: $icons-subdirectory-arrow-right;
    }
}
.icons-suitcase {
    &:before {
        content: $icons-suitcase;
    }
}
.icons-suitcase-medical {
    &:before {
        content: $icons-suitcase-medical;
    }
}
.icons-suitcase-outline {
    &:before {
        content: $icons-suitcase-outline;
    }
}
.icons-suitcase-rolling {
    &:before {
        content: $icons-suitcase-rolling;
    }
}
.icons-supersaver {
    &:before {
        content: $icons-supersaver;
    }
}
.icons-supersaver-outline {
    &:before {
        content: $icons-supersaver-outline;
    }
}
.icons-swap-vert {
    &:before {
        content: $icons-swap-vert;
    }
}
.icons-swim {
    &:before {
        content: $icons-swim;
    }
}
.icons-talk {
    &:before {
        content: $icons-talk;
    }
}
.icons-talk-outline {
    &:before {
        content: $icons-talk-outline;
    }
}
.icons-ticket {
    &:before {
        content: $icons-ticket;
    }
}
.icons-ticket-outline {
    &:before {
        content: $icons-ticket-outline;
    }
}
.icons-trash {
    &:before {
        content: $icons-trash;
    }
}
.icons-trash-outline {
    &:before {
        content: $icons-trash-outline;
    }
}
.icons-trophy {
    &:before {
        content: $icons-trophy;
    }
}
.icons-trophy-outline {
    &:before {
        content: $icons-trophy-outline;
    }
}
.icons-umbrella-beach {
    &:before {
        content: $icons-umbrella-beach;
    }
}
.icons-user {
    &:before {
        content: $icons-user;
    }
}
.icons-user-group {
    &:before {
        content: $icons-user-group;
    }
}
.icons-user-group-outline {
    &:before {
        content: $icons-user-group-outline;
    }
}
.icons-user-outline {
    &:before {
        content: $icons-user-outline;
    }
}
.icons-utensils {
    &:before {
        content: $icons-utensils;
    }
}
.icons-utensils-slash {
    &:before {
        content: $icons-utensils-slash;
    }
}
.icons-van-shuttle {
    &:before {
        content: $icons-van-shuttle;
    }
}
.icons-video-sq {
    &:before {
        content: $icons-video-sq;
    }
}
.icons-video-sq-outline {
    &:before {
        content: $icons-video-sq-outline;
    }
}
.icons-visibility {
    &:before {
        content: $icons-visibility;
    }
}
.icons-visibility-off {
    &:before {
        content: $icons-visibility-off;
    }
}
.icons-wallet {
    &:before {
        content: $icons-wallet;
    }
}
.icons-wallet-outline {
    &:before {
        content: $icons-wallet-outline;
    }
}
.icons-warning {
    &:before {
        content: $icons-warning;
    }
}
.icons-water-slide {
    &:before {
        content: $icons-water-slide;
    }
}
.icons-wheelchair {
    &:before {
        content: $icons-wheelchair;
    }
}
.icons-wheeled-luggage {
    &:before {
        content: $icons-wheeled-luggage;
    }
}
.icons-wheeled-luggage-add {
    &:before {
        content: $icons-wheeled-luggage-add;
    }
}
.icons-wheeled-luggage-off {
    &:before {
        content: $icons-wheeled-luggage-off;
    }
}
.icons-wifi {
    &:before {
        content: $icons-wifi;
    }
}
.icons-work {
    &:before {
        content: $icons-work;
    }
}
.icons-world {
    &:before {
        content: $icons-world;
    }
}
.icons-zoom-in {
    &:before {
        content: $icons-zoom-in;
    }
}
.icons-zoom-in-outline {
    &:before {
        content: $icons-zoom-in-outline;
    }
}
