//Commited 19-agosto
/*      Tour List    */
.big-body {
  #divListTours.big-content.product-list {
    div#left {
      margin: 0px 0px 0px 0px;
      width: 100% !important;
    }
    display: grid;
    grid-template-columns: 2fr 10fr;
    grid-gap: 32px;
    width: 100%;
    .tours-list {
      a {
        text-decoration: none;
        &::hover {
          text-decoration: none;
        }
      }
    }
    #ConTit {
      width: 100%;
    }
  }
}
/*   End Tour List   */

/*      Grid Cards Tour    */
.grid-cards-tour {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
}
/*   End Grid Cards Tour   */

/*      Cards Tour    */
.card-tour {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  gap: 0px;
  overflow: hidden;
  position: relative;
  border: 1px solid #d3d3d3;
  color: #555555 !important;
}

.card-tour {
  .information-container,
  .pricing-container {
    gap: 4px;
    padding: 8px;
    p {
      margin: 0px;
    }
  }
}
.card-tour {
  .image-container {
    width: 100%;
    img {
      width: 100%;
      height: auto;
    }
    span.promo-label {
      display: block;
      padding: 4px 8px;
      position: absolute;
      top: 8px;
      right: 8px;
      width: unset;
    }
  }
  .information-container {
    height: 100%;
    .location {
      width: 100%;
      color: #717171;
      text-indent: 0 !important;
    }
    .name {
      width: 100% !important;
      font-size: 14px;
    }
    .badge {
      margin: 4px 0px;
    }
  }
  .pricing-container {
    background-color: #f2f2f2;
    position: relative;
    .p-points {
      position: relative;
      margin: 0px !important;
      padding: 2px;
      display: block;
      width: max-content;
    }
    .badge-discount {
      background-color: #489a00;
      color: #f2f2f2;
      padding: 2px 3px;
      margin-left: 16px;
      text-decoration: none;
      position: relative;
      font-weight: bold;
      &::before {
        position: absolute;
        content: "";
        display: inline-block;
        right: 100%;
        top: 0;
        bottom: 0;
        width: 0;
        height: 0;
        border-top: 11px solid transparent;
        border-right: 8px solid #489a00;
        border-bottom: 10px solid transparent;
      }
    }
    .last-pricing {
      position: relative;
      color: #717171;
      &::after {
        content: "";
        display: block;
        width: 110%;
        height: 1.5px;
        position: absolute;
        background-color: #717171;
        left: 50%;
        top: 50%;
        translate: -50%;
      }
    }
    .pricing {
      font-size: 16px;
      line-height: 150%;
    }
  }
}
.card-tour {
  &:hover {
    color: #555555 !important;
    text-decoration: none;
    box-shadow:
      0 5px 5px -3px rgba(0, 0, 0, 0.02),
      0 8px 10px 1px rgba(0, 0, 0, 0.14),
      0 3px 14px 2px rgba(0, 0, 0, 0.12);
    transition: 0.2s ease-in-out;
  }
}

/*   End Cards Tour   */

/*      Icon Color    */
.icons-flame.flame {
  font-size: 1.2em;
  color: $orange;
}
/*   End Icon Color   */

/*      Grid Cards Responsive    */
@media (max-width: 992px) {
  .big-body {
    #divListTours.big-content.product-list {
      grid-template-columns: 3fr 9fr;
      padding: 0px 16px;
    }
  }
  .grid-cards-tour {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (max-width: 768px) {
  .big-body {
    #divListTours.big-content.product-list {
      grid-template-columns: 1fr;
    }
  }
  .grid-cards-tour {
    grid-template-columns: 1fr 1fr;
    grid-gap: 12px;
  }
}
@media (max-width: 375px) {
  .grid-cards-tour {
    grid-template-columns: 1fr;
  }
}
/*   End Grid Cards Responsive   */

/*   cards element   */

.pt-customJqueryUi .ptw-icon-places-tour:before {
  font-family: "PTH-icons";
  content: "\30";
}
.pt-customJqueryUi .ptw-icon-places-city:before {
  font-family: "PTH-icons";
  content: "\e97a";
}
