.asideTS__title--texto-radio{
        
}

.asideTS__section--input-range{
    width: 100%;
}

.asideTS__section--form{
    display: grid; 
    grid-template-columns: 1fr 1fr; 
    gap: 8px;
    width: 100%; 
}

.asideTS__section--form-input{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 44px;
    border-radius: 4px;
    border: 1px solid #e0e0e0;
}