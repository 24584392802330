// Estilos para la vista de ayuda en la versión mobile
@media (max-width: 767px) {
  .help-page .modulo3 {
    width: 100%;
    float: left;
    margin: 20px 30px;
    min-height: 4em;
    display: block;
  }
}
