:root {
   /** UpSell*************/
    /* Fondo principal del modal */
    --upsell-background-color-1: white;
    /* Color de fondo de ver mas detalles */
    --upsell-background-color-2: #f0f6ff;
    /* Color de la seccion de precio no seleccionada*/
    --upsell-background-color-3: #fdfdfd;
    /* Color de la seccion de precio ya seleccionada */
    --upsell-background-color-4: #07288c;

    /* borde superior del footer e inferior del header */
    --upsell-border-color-1: #BFC2C7;
    
    /* Color de titulo del article y footer*/
    --upsell-text-color-titulo-1: #07288c;
    /* Color del detalle del titulo de la seccion detalle de vuelo */
    --upsell-text-color-titulo-2: #1C1C1C;
    /* Color del titulo de las amenidades del vuelo */
    --upsell-text-color-titulo-3: #404040;
    /*Color de la parte del titulo para el upsell en el skeleton*/
    --upsell-text-color-titulo-skeleton: #07288cab;

    /* Texto de la seccion de precios no seleccionados y secundarios*/
    --upsell-text-color-1: #8C8C8C;
    /* Texto que para los elementos que parecen link */
    --upsell-text-color-2: #186cdf;
    /* Texto que resalta  */
    --upsell-text-color-3: #1C1C1C;
    /* Texto de la seccion de precios seleccionados */
    --upsell-text-color-4: white;  

    /* Borde de color defalt de los article*/
    --upsell-border-color-card: #07288c;
    /* Borde de la linea superior del footer y por debajo del header */
    --upsell-border-color-1: #c1bfc7;

    /* Color de fondo de los botones de scroll */
    --upsell-boton-control-background: #07288c;
  
    /* Color del boton principal de upsell */
    --upsell-boton-background-1: #07288c;
    /* Color del texto para el boton */
    --upsell-boton-text-1: white;
   /** UpSell*************/






  /*! General ************/
  --text-size-xxs: 1.2rem;
  --text-size-xs: 1.4rem;
  --text-size-sm: 1.6rem;
  --text-size-md: 1.8rem;
  --text-size-lg: 2rem;
  --text-size-xl: 2.4rem;
  --text-size-xxl: 2.8rem;
  --text-size-xxxl: 3.2rem;

  --font-family-1: poppins, sans-serif;
  --font-family-2: sans-serif;


  --border-radius: 4px;
  --border-size-1: 1px;
  --border-color-1: var(--color-affiliate-7);
  --border-color-2: var(--color-affiliate-8);


  /** Los colores neutrales estan separos en 2 secciones una de obscuros y claros */
  /** Esto para facilitar el uso del Dark/Ligth mode */
  --neutral-color-1: #0D0D0D; /* 950 */
  --neutral-color-2: #1C1C1C; /* 900 */
  --neutral-color-3: #292929; /* 800 */
  --neutral-color-4: #404040; /* 700 */
  --neutral-color-5: #707070; /* 600 */
  --neutral-color-6: #8C8C8C; /* 500 */
  
  --neutral-color-7: #9C9C9C; /* 400 */
  --neutral-color-8: #C7C7C7; /* 300 */
  --neutral-color-9: #E5E5E5; /* 200 */
  --neutral-color-10: #F0F0F0; /* 100 */
  --neutral-color-11: #FAFAFA; /* 50 */
  --neutral-color-12: #FFFFFF; /* White */






  /* --base-color-1: white;
  --base-color-2: var(--color-3);
  --base-color-3: #666666;
  --base-color-4: #007CC2;
  --base-color-5: #4C4C4C;
  --base-color-6: #E6F2F9;
*/



  // --base-color-1: #FFFFFF;
  // --base-color-2: #186BDF;
  // --base-color-3: #1C8207;
  // --base-color-4: #53AF95;
  --semantic-color-1: #F2F5FC;
  --semantic-color-2: #3061F2;
  --semantic-color-3: #489A00;

  
  --alert--semantic-success: #2BA612;
  --alert--semantic-warning: #F2BD0D;
  --alert--semantic-error: #D93616;
  --alert--semantic-info: #3061F2;



  //? Texto de información
  --semantic-info-color-1: #186BDF; /* Color de texto */
  --semantic-info-color-2: #B5C5F5; /* Color de borde */
  --semantic-info-color-3: #F2F5FC; /* Color de fondo */
  //? Texto de información

  //! Texto de error
  --semantic-warning-color-1: #C9430A;
  --semantic-warning-color-2: #D93616;
  --semantic-warning-color-3: #FCF4F2;
  //! Texto de error

  //* Texto de éxito
  //* Texto de éxito


  //Texto de warning
  //Texto de warning




  /* --base-color-2: var(--color-3);
  --base-color-3: #666666;
  --base-color-4: #007CC2;
  --base-color-5: #4C4C4C;
  --base-color-6: #E6F2F9; */
  /*! General ************/






  /** Utilizado en los botones principales */
  --color-affiliate-1: #07288C;

  /** Este color debe de tener un contraste respecto al color "--color-affiliate-1" */
  /** ya que se usan en conjunto generalmente en botones */
  --color-affiliate-2: white;

  /** Usado como boton secundario o para dar efecto hover al boton principal */
  /** ademas de tener contraste con el color "--color-affiliate-2" */
  --color-affiliate-3: #07288C;

  /** Este color se usa para los textos y debe ser altamente contrastante */
  /** uso recomendado solo en titulos */
  --color-affiliate-4: #18161C;

  /** Este color se usara para los "background-color" */
  --color-affiliate-5: #CCE0FF;

  /** Este color debe igual pero menos intenso al "--color-affiliate-5" ya que se se pueden usar */
  /** en conjunto y por eso debe contrastar mas que el otro (hovers, selects, etc...) */
  --color-affiliate-6: #F0F6FF;

  /** Este color se usara para los "border-color" */
  --color-affiliate-7: #C1BFC7;

  /** Este color debe igual pero menos intenso al "--color-affiliate-7" para tener 2 opciones */
  /** de resaltado de bordes */
  --color-affiliate-8: #E4E4E6;

  /** Se usa para definir el color de fondo (BODY) */
  --color-affiliate-9: white;
  
  /** Este color debe de tener un contraste sutil respecto al color "--color-affiliate-9" */
  /** ya que se usara en elementos que grandes con los que se quiera ganar un contraste */
  --color-affiliate-10: white;





  /** Variables Aeromexico *************/
  /* --color-affiliate-1: #fa0073;
  --color-affiliate-2: #f2f2f0;
  --color-affiliate-3: #fa0073;
  --color-affiliate-4: #020c41;
  --color-affiliate-5: #EBF5FA;
  --color-affiliate-6: #F5FAFD;
  --color-affiliate-7: #C4DCEE;
  --color-affiliate-8: #C4DCEE;
  --color-affiliate-9: white;
  --color-affiliate-10: white; */


}

