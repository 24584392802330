.section__alert {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
}
.alert__container {
    display: flex;
    width: 100%;
    box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.12);
    border-left: 5px solid black;
}
.alert__picture {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 16px;
}
.alert__picture--icon {
    width: 24px;
    height: 24px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}
.alert__description {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    padding: 18px 16px 16px 0px;
}
.alert__description--header {
    display: flex;
    justify-content: space-between;
}
.alert__description--header-title {
    font-family: var(--font-family);
    font-size: var(--text-size-sm);
    font-weight: 600;
}

.alert__description--header-close {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    cursor: pointer;

    & path{
        stroke: #18161C;
    }
}

.alert__description--text {
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-family: var(--font-family);
    font-size: var(--text-size-xs);
    color: #696770;
}

.alert__description--text-span{
    color: #010101;
}


.alert__description--button{
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    font-size: var(--text-size-sm);
    padding: 12px 16px;
    background-color: #07288C;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}

.alert__container--success{
    border-color:var(--alert--semantic-success);

    .alert__description--header-title{
        color: var(--alert--semantic-success);
    }    

    .alert__picture--icon{
        background-image: url("https://b2b2c.cdnpt.com/en-us/images/check.svg");
    }
}

.alert__container--warning{
    border-color:var(--alert--semantic-warning);

    .alert__description--header-title{
        color: var(--alert--semantic-warning);
    }    

    .alert__picture--icon{
        background-image: url("https://b2b2c.cdnpt.com/en-us/images/warning.svg");   
    }
}

.alert__container--error{
    border-color:var(--alert--semantic-error);

    .alert__description--header-title{
        color: var(--alert--semantic-error);
    }    

    .alert__picture--icon{
        background-image: url("https://b2b2c.cdnpt.com/en-us/images/danger.svg");   
    }
}

.alert__container--info{
    border-color:var(--alert--semantic-info);

    .alert__description--header-title{
        color: var(--alert--semantic-info);
    }    

    .alert__picture--icon{
        background-image: url("https://b2b2c.cdnpt.com/en-us/images/info.svg");   
    }
}


.section__mini--alert{
    
    .alert__picture{
        padding: 4px;
        width: 25px;
        height: 25px;
    }

    .alert__description{
        padding: 4px 8px 4px 0px;
        gap: 0px;
    }

    .alert__description--header-title{
        font-size: var(--text-size-xs);
    }

    .alert__description--text{
        font-size: var(--text-size-xxs);
    }
}