@import "amenidades";
@import "detalle-vuelo";
@import "skeleton";
@import "fix-master";
@import "calculadora";
@import "aerolineas/styles.scss";



.disabled__scroll{
    overflow-y: hidden;
}

.modalUS{
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    height: 100dvh;
    background-color: rgba(0, 0, 0, .4);
    z-index: 100000;
    overflow: hidden;
    top: 0px;
    left: 0px;
    @media (min-width: 800px){
        justify-content: flex-end;
    }
}

.modalUS__container{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: var(--upsell-background-color-1);
    overflow: hidden;

    @media (min-height: 760px) {
        border-radius: 8px 8px 0px 0px;
        height: 95%;
    }

    @media (min-width: 800px){
        width: 70%;
        height: 100%;
        border-radius: 0px;
    }
}

.modalUS__header{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: auto;
}

.modalUS__header--container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    width: 100%;
    padding: 16px 12px;
    border-bottom: 1px solid var(--upsell-border-color-1);

    @media (min-width: 800px){
        padding: 20px 28px;
    }
}

.modalUS__header--title{
    width: calc(100% - 32px);
    font-size: 1.6rem !important;
    font-weight: 600;
    color: var(--upsell-text-color-titulo-2);
    font-family: var(--font-family);
    font-weight: 600;

    @media (min-width: 340px){
        font-size: 2rem !important;
    }
}

.modalUS__header--close{
    width: 32px;
    height: 32px;
    cursor: pointer;
    
    path{
        stroke: var(--upsell-text-color-titulo-2);
    }
}

.modalUS__main{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    scrollbar-width: none;
}

$media-query-1: 768px;

.modalUS__main--articles{
    gap: 8px;
    width: 100%;
    height: 100%;
    // padding: 0px 12px;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;

    @media (min-width: 800px){
        padding: 0px 28px;
    }

    @media (min-width: $media-query-1) {
        flex-direction: row;
        gap: 8px;
        width: 100%;
        height: 95%;
        padding: 0px 12px;
        overflow-x: scroll;
        overscroll-behavior-x: contain;
        scrollbar-width: none;
        -ms-overflow-style: none;
        margin: 0px;
        padding: 0px;
    } 
}

.modalUS__main--articles-container{
    width: 100%;
    height: 1px;
    padding: 0px 12px;

    @media (min-width: $media-query-1) {
        display: flex;
        flex-direction: row;
        gap: 8px;
        width: 100%;
        height: 100%;
        padding: 0px 12px;
        overflow-x: scroll;
        scrollbar-width: none;
        -ms-overflow-style: none;
        padding: 12px;
    }

    @media (max-height: 500px) {
        padding: 0px;
    }
}



.modalUS__main--articles::-webkit-scrollbar {
    display: none;
}

.modalUS__main--button{
    display: none;

    @media (min-width: 800px){
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: none;
        background-color: var(--upsell-boton-control-background);
        z-index: 1000;
        cursor: pointer;
        top: calc(50% - 20px);
    }

    &[disabled]{
        display: none;
    }
}
.modalUS__main--button-left{
    left: 10px;
    padding-right: 2px;
}
.modalUS__main--button-right{
    right: 10px;
    padding-left: 2px;
}

.modalUS__article{
    position: relative;
    display: flex;
    scroll-snap-align: center;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-width: 300px;
    min-height: 95%;    
    max-width: 330px;
    border-radius: 4px;
    border: 2px solid var(--upsell-border-color-card);
    border-top: 7px solid var(--upsell-border-color-card);
    overflow: hidden;
    cursor: pointer;

    &:first-child{
        margin-left: 10px;
    }

    &:last-child{
        margin-right: 10px;
    }


    &:focus{
        outline: 1px double black;
        box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);       
    }

    @media (max-width: $media-query-1){
        max-width: 100%;
        margin-top: 15px;

        &:first-child{
            margin-top: 5px;
            margin-left: 0px;
        }

        &:last-child{
            margin-right: 0px;
        }

        height: 230px;
    }
}


.modalUS__article--section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    width: 100%;
    height: auto;

}

.modalUS__article--selected{
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);

    & .modalUS__article--checkbox-custom{
        position: relative;
        background-color: var(--upsell-text-color-titulo-1);
        border-color: black;

        &::after{
            content: "";
            width: 11px;
            height: 7px;
            background-color: var(--upsell-text-color-titulo-1);
            position: absolute;
            top: 0px;
            left: 3px;
            transform: rotate(315deg);
            border-radius: 0 10px 0 0;
        }
        &::before{
            content: "";
            width: 11px;
            height: 7px;
            background-color: white;
            position: absolute;
            top: 4px;
            left: 3px;
            transform: rotate(315deg);
        }
    }

    &.modalUS__article{
        height: auto;
    }
}
.modalUS__article--container{
    display: flex;
}

.modalUS__article--icon{
    position: relative;
    display: grid; 
    place-items: center;   
    width: 0px;
    height: 0px;
}

.modalUS__article--title {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 2rem;
    color: var(--upsell-text-color-titulo-1);
    font-family: var(--font-family);
}

.modalUS__article--checkbox-custom {
    width: 18px;
    height: 18px;
    border: 1px solid black;
    border-radius: 50%;
}

.modalUS__article--section-price{
    position: absolute;
    bottom: 0px;
    display: grid;
    place-items: center;
    width: 100%;
    height: 90px;
    background-color: var(--upsell-background-color-2);
    border-top: 1px solid #D9D9D7;


    &::before{
        position: absolute;
        content: "";
        display: block;
        width: 100%;
        height: 80px;
        top: -80px;
        z-index: 100;
        background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255, 0) 100%);
        pointer-events: none;
    }

    @media (max-width: $media-query-1) {
        &::before{
            display: none;
        }    
    }

    @media (max-height: 500px) {
        height: 60px;

        & .modalTD__article--container-price{
            padding: 2px 8px;

            & *{
                line-height: 100%;
            }
        }

        &::before{
            height: 40px;
            top: -40px;
        }
    }

    
}

.modalUS__article--container-price{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    height: 70%;
    background-color: var(--upsell-background-color-3);
    padding: 0px 8px;
    border-radius: 4px;
    border: 1px solid #E4E4E6;
}
.modalUS__article--container-price-description{
    font-size: 1.3rem;
    max-width: 16ch;
    font-family: var(--font-family);
    color: var(--upsell-text-color-3);
}
.modalUS__article--container-price-title{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    font-size: 1.6rem;
    font-family: var(--font-family);
    color: var(--upsell-text-color-3);
}
.modalUS__article--selected{
    & .modalUS__article--container-price{
        background-color: var(--upsell-background-color-4);
    }

    & .modalUS__article--container-price-description{
        color: var(--upsell-text-color-4);
    }

    & .modalUS__article--section-price .modalUS__article--container-price .modalUS__article--container-price-title-span{
        color: var(--upsell-text-color-4);
    }
}




.modalUS__footer{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
    width: 100%;
    height: 120px;
    padding: 12px 16px;
    border-top: 1px solid var(--upsell-border-color-1);

    @media (min-width: 800px) and (min-height: 670px){
        padding: 4px 28px;
    }

    @media (min-width: 800px) and (min-height: 710px){
        padding: 12px 28px;
    }
}


.modalUS__footer--container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    height: auto;
}


.modalUS__footer--container-title,
.modalUS__footer--container-description {
    font-size: 1.4rem;
    color: var(--upsell-text-color-titulo-2);
    font-family: var(--font-family);


    @media(max-width: 330px){
        text-align: end;
    }
    
    @media(min-width: 800px){
        font-size: 1.5rem;
    }
}

.modalUS__footer--container-price {
    font-size: 2rem;
    color: var(--upsell-text-color-titulo-2);
    font-family: var(--font-family);

    @media (min-width: 800px){
        font-size: 2.2rem;
    }
}






.modalUS__footer--button{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    color: var(--upsell-boton-text-1);
    font-size: 1.6rem;
    background-color: var(--upsell-boton-background-1);
    height: 60px;
    border-radius: 4px;
    border: none;

    @media (min-width: 800px){
        width: 200px;
        height: 60px;
        margin-right: 0px;
        font-size: 1.8rem;
    }
}


.modalUS__animation--entry{
    animation: fade .3s linear;
}

.modalUS__container--animation-entry{
    animation: popUp .3s linear;

    @media (min-width: 600px){
        animation: popLeft .3s linear;
    }
}

.modalUS__animation--exit{
    animation: fadeExit .3s linear;
}

.modalUS__container--animation-exit{
    animation: popDown .3s linear;

    @media (min-width: 600px){
        animation: popRight .3s linear;
    }
}

.modalUS__article{
    animation: slide 1.1s linear;
    @media (min-width: 600px){
        animation: none;
    }
}

@keyframes slide {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(0px);
    }
    85% {
        transform: translateY(-40px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes fade {
    0% {
        background-color: rgba(0, 0, 0, 0);
    }
    100% {
        background-color: rgba(0, 0, 0, .4);
    }
}
    
@keyframes popUp {
    0% {
        transform: translateY(97%);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes popLeft {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes popDown {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(97%);
    }
}

@keyframes popRight {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(100%);
    }
}

@keyframes fadeExit {
    0% {
        background-color: rgba(0, 0, 0, .4);
    }
    100% {
        background-color: rgba(0, 0, 0, 0);
    }
}